export class FlightSearchOptions {


	// public enum SeatClass{
	// 	ECONOMY,
	// 	PREMIUM_ECONOMY,
	// 	BUSINESS,
	// 	FIRST_CLASS,
	// 	PREMIUM_FIRST,
	// 	BASIC_ECONOMY;

	// 	}

	adultCount: number = 1;
	childCount: number = 0;
	infantCount: number = 0;
	combo: boolean;
	seatClass: string;
	algoType: string;






	public getIsCombo(): boolean {
		return this.combo;
	}










	public getInfantCount() {
		return this.infantCount;
	}






	private tripType: string;

	public getTripType() {
		return this.tripType;
	}




	public getAdultCount() {
		if (this.adultCount > 0) return this.adultCount;
		return 1;
	}




	public getChildCount() {
		return this.childCount;
	}






	// @JsonIgnore
	// public String getTravellerDetails(){
	// 	String text="";
	// 	if (adultCount + childCount > 1){
	// 		if (adultCount>0){
	// 			text += adultCount + " Adult" + ((adultCount>1)?"s":"");
	// 		}
	// 		if (childCount >0){
	// 			if (adultCount>0){
	// 				text +=", ";
	// 			}
	// 			text += childCount + ((childCount>1)?" Children":" Child");
	// 		}
	// 	}
	// 	return text;
	// }


	public getTotalTravellers() {
		return this.adultCount + this.childCount + this.infantCount;
	}

}