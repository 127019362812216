import { HotelEmailDTO } from "./hotel-email-dto";
import { FlightLegEmailDTO } from "./flight-leg-email-dto";
import { FlightSearchOptions } from "./flight-search-options";
import { CarResult } from "../carResult";
import { CarEmailDTO } from './car-email-dto';

export class EmailSearchV4DTO {

	ticketId: string;
	tripId: string;
	appointment_location: string
	sourceLocation: string;
	appointment_start_date_time: string;
	appointment_end_datetime: string;
	hotelOptions: Array<HotelEmailDTO>;
	hotelOptionsList: Array<Array<HotelEmailDTO>>;
	carOptions: Array<any>;
	carOptionsList: Array<Array<CarEmailDTO>>;
	flightLegRequests: Array<any>;
	eventList: Array<string>;
	eventIdAndTypeMap: Map<string, string>;
	policySet: boolean;

	leg1Destination: string;
	leg2Destination: string;

	approvalRequiredFor: string;
	google_map_image: string;

	appointmentCity: string;

	view_more_url: string;

	meetingStartTime: string;
	meetingEndDate: string;
	meetingEndTime: string;
	returnDate: string;
	
	name: string;
	flight_pref_list: Array<string>;

	multiFlightOptions: Array<Array<Array<FlightLegEmailDTO>>>;

	flightSearchOptions: FlightSearchOptions;




	resultDate: string;
	resultTime: string;



	ticketNumber: string;
	isUserSelected: boolean;
	eventIdAndTypeMapDestination: any;



}
