<div #modal class="show" id="">
  <div class="modal-header">
    <h5 class="modal-title" style=""> {{'feedback.Hotelreview' | translate}}</h5>
    <button type="button" class="close pull-right cross-button" aria-label="Close" (click)="acceptClick(false);">
      <span style="color:#fff;" class="icon-cross modal-cross"></span>
    </button>
  </div>
  <div class="modal-body">
    <div style="margin-top:5px;margin-bottom:10px;">
      <img class="bed-image" src="assets/images/like_dislike_icons.png">
    </div>
    <div *ngIf="!this.userAccountService.feedbackSubmitSuccessfully" style="max-width:  350px;margin:  0 auto;">
      <h4 style="font-weight: bold;margin-bottom:10px;text-align: left;"> {{'feedback.Howwasyourexperience' | translate}}</h4>
      <div class="feature">
        <span style="margin-right:7px;text-transform: uppercase;"> {{'feedback.Hotel' | translate}}:</span><span>{{hotelName}}</span>
      </div>
      <div class="feature">
        <span style="margin-right:7px;text-transform: uppercase;"> {{'feedback.Check-IN' | translate}}:</span><span>{{getDisplayDate(checkin, 'MMM
          d')}}</span>
      </div>
      <div class="feature">
        <span style="margin-right:7px;text-transform: uppercase;"> {{'feedback.Check-OUT' | translate}}:</span><span>{{getDisplayDate(checkout, 'MMM
          d')}}</span>
      </div>
      <app-hotelfeedback [tripId]="tripId" [ticketId]="ticketId" [transactionId]="transactionId" [eventId]="eventId"
        [component]="'feedback'"></app-hotelfeedback>
    </div>
    <div *ngIf="this.userAccountService.feedbackSubmitSuccessfully" style="text-align:center;margin-top:20px; ">
      <span style="color:green;cursor:pointer;font-size:25px;position: relative;margin-top:30px;"><i
          class="fa fa-check-circle" aria-hidden="true"></i></span><br>
      <span style="margin-top:20px;">{{'feedback.Yourreviewissubmitted.' | translate}}</span><br>
      <h2 style="font-weight: bold;margin-top:20px;"> {{'feedback.Thankyou' | translate}}</h2>
    </div>
  </div>


</div>