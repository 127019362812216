import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, ActivatedRoute, Params } from '@angular/router';
import { Observable } from 'rxjs';
import { deserialize } from '../util/ta-json/src/methods/deserialize';
import { FlightSearchQueryParam } from '../entity/flight-search-query-param';
import { GallopLocalStorageService } from '../gallop-local-storage.service';

@Injectable()
export class SearchResultGuard implements CanActivate {

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private gallopLocalStorage: GallopLocalStorageService
  ) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {

    let queryParams = next.queryParams;
    if (queryParams && queryParams && Object.keys(queryParams).length > 0) {

      let flightSearchQueryParam: FlightSearchQueryParam = deserialize(JSON.parse(decodeURIComponent(queryParams.query)), FlightSearchQueryParam);
      let bookingWizardStep = Number(queryParams.step);

      if (bookingWizardStep == 0) {
        return true;
      } else if (bookingWizardStep == 1 &&
        this.gallopLocalStorage.getItem("selectedFlight") &&
        this.gallopLocalStorage.getItem("flightSearchRequest") &&
        this.gallopLocalStorage.getItem("uniqueAirlines") &&
        this.gallopLocalStorage.getItem("airports") &&
        this.gallopLocalStorage.getItem("uniqueAirlines")) {

        return true;
      }
    }

    this.router.navigate(["search"], { replaceUrl: true });
    return false;
  }
}
