import "reflect-metadata";
import { getDefinition } from "../classes/object-definition";

// tslint:disable:ext-variable-name only-arrow-functions

export function JsonProperty(options?: string | symbol | { name?: string | symbol, type?: Function, elementType?: Function, readonly?: boolean, writeonly?: boolean }): PropertyDecorator {
    return function (target: any, key: string): void {
        const property = getDefinition(target.constructor).getProperty(key);
        const type = Reflect.getMetadata("design:type", target, key);
        let serializedName: string;
        if (typeof options == 'object') {
            serializedName = options.name as string;
            if (options.type) {
                property.type = options.type;
            }
            if (options.elementType) {
                property.elementType = options.elementType;
            }
            property.readonly = !!options.readonly;
            property.writeonly = !!options.writeonly;
        } else {
            serializedName = options as string;
        }
        property.serializedName = serializedName || key;
        if (!property.type) {
            property.type = type;
        }
    };
}
