import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Title } from '@angular/platform-browser';
import { DeviceDetailsService } from '../device-details.service';
import { Subscription } from 'rxjs';
import { SearchService } from '../search.service';
import { UserAccountService } from '../user-account.service';
import { CommonUtils } from '../util/common-utils';
declare var isCurrentUrlADashboardUrl;
@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  amnetSpecificRelease: boolean = environment.amnetSpecificRelease;
  njoySpecificBuild: boolean;
  show = false;
  deviceSubscription: Subscription;
  title = '';

  constructor(private titleService: Title,
    private userAccountService: UserAccountService,
    public deviceDetailsService: DeviceDetailsService,
    public searchService: SearchService,) { }

  ngOnInit() {
    this.njoySpecificBuild = this.userAccountService.isItNjoyBuild();
    this.title = this.titleService.getTitle();
    //this.deviceSubscription = this.deviceDetailsService.isMobile().subscribe(isMobile => {
    // this.isMobile = isMobile;
    // });

  }
  isDashboardPage() {
    if (this.userAccountService.getCurrentRouterPath()) {
      return (CommonUtils.doesPathContain(this.userAccountService.getCurrentRouterPath(), 'admin')
        ||
        isCurrentUrlADashboardUrl()
      );
    }
  }
}
