import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { UserAccountService } from '../user-account.service';
import { ClientConfiguration } from '../client-config.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-hotelfeedback',
  templateUrl: './hotelfeedback.component.html',
  styleUrls: ['./hotelfeedback.component.scss']
})
export class HotelfeedbackComponent implements OnInit {
  @Input() ticketId: string;
  @Input() eventId: any;
  @Input() tripId: any;
  @Input() transactionId: any;
  @Input() component: string;
  constructor(
    public userAccountService: UserAccountService,
    private translate: TranslateService,
    private clientConfig: ClientConfiguration) { }
  url = "assets/images/ thumsup.png";
  url1 = "assets/images/Untitled_design.png";
  dislike = false;
  buttonText = 'Submit';
  like = false;
  selectReasoon = [];
  feedback = 'NONE';
  ngOnInit() {
    if (this.component === 'booking') {
      this.buttonText = this.translate.instant('feedback.Save');
      if (this.userAccountService.historyFeedBack) {
        this.feedback = this.userAccountService.historyThumb;
        this.selectReasoon = this.userAccountService.hotelReason;
        if (this.feedback === 'UP') {
          this.url = "assets/images/Untitled_design-2_copy.png";
          this.like = true;
          this.dislike = false
        } else if (this.feedback === 'DOWN') {
          this.like = false;
          this.dislike = true;
          this.url1 = "assets/images/Untitled_design_copy.png"
        }
      }
    } else {
      this.buttonText = this.translate.instant('feedback.Submit');;
    }

  }


  acceptClick(showSuccess) {
    this.userAccountService.historyFeedBack = true;
    this.userAccountService.hotelFeedBack(this.tripId, this.ticketId, this.transactionId, this.eventId, this.feedback, this.selectReasoon).subscribe(resp => {
      if (resp.status === 'success' && showSuccess) {
        this.userAccountService.feedbackSubmitSuccessfully = true;
        if (this.component === 'booking') {
          this.userAccountService.historyFeedBack = true;
          this.userAccountService.feedbackMsg = 'Your feedback';
          this.userAccountService.historyThumb = this.feedback;
          this.userAccountService.hotelReason = this.selectReasoon
        }
      } else {
        this.userAccountService.feedbackSubmitSuccessfully = true;
      }
    })
  }
  getUrl() {
    if (this.userAccountService.historyFeedBack) {
      return;
    }
    if (!this.like) {
      if (this.dislike) {
        this.dislike = false;
        this.url1 = "assets/images/Untitled_design.png"
      }
      this.feedback = 'UP';
      this.like = true;
      this.selectReasoon = [];
      this.url = "assets/images/Untitled_design-2_copy.png"
      this.url1 = "assets/images/Untitled_design.png"

    } else {
      this.like = false;
      this.feedback = 'NONE';
      this.url = "assets/images/ thumsup.png"
    }
  }
  getUrl1() {
    if (this.userAccountService.historyFeedBack) {
      return;
    }
    if (!this.dislike) {
      if (this.like) {
        this.like = false;
        this.url = "assets/images/ thumsup.png"
      }
      this.dislike = true;
      this.feedback = 'DOWN';
      this.url1 = "assets/images/Untitled_design_copy.png"
    } else {
      this.dislike = false;
      this.selectReasoon = [];
      this.feedback = 'NONE';
      this.url1 = "assets/images/Untitled_design.png";
    }
  }
  getSelectReason(item) {
    if (this.userAccountService.historyFeedBack) {
      return;
    }
    let index = this.selectReasoon.indexOf(item);
    if (index === -1) {
      this.selectReasoon.push(item)
    } else {
      this.selectReasoon.splice(index, 1);
    }

  }
  isSelected(item) {
    if (this.userAccountService.historyFeedBack && !this.selectReasoon) {
      return;
    }
    let index = this.selectReasoon.indexOf(item);
    if (index > -1) {
      return true;
    } else {
      return false;
    }
  }
  getBrandName() {
    return this.clientConfig.brandName;
  }
}
