export enum FilterType {
  AIRLINE = "AIRLINE",
  AIRPORT = "AIRPORT",
  STOPS = "STOPS",
  TIME = "TIME",
  POLICY = "POLICY",
  HOTEL_CHAIN = "HOTEL_CHAIN",
  HOTEL_STARS = "HOTEL_STARS",
  HOTEL_AMENITIES = "HOTEL_AMENITIES",
  HOTEL_NAME = "HOTEL_NAME",
  CAR_NAME = "CAR_NAME",
  CAR_TYPE = "CAR_TYPE",
  CAR_BRAND = "CAR_BRAND",
  CAR_SPECIFICATION = "CAR_SPECIFICATION",
  CAR_LOCATION = "CAR_LOCATION",
  CAR_LOCATION_TYPE = "CAR_LOCATION_TYPE",
}
