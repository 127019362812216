<email-header></email-header>

<div class="main-wrapper">
  <div class="content">
    <div class="container">
      <div class="itinerary-container" *ngIf="!isMobile">
        <div class="itinerary-card">
          <div *ngIf="!hotelShow">
            <div class="card-heading">{{'itinerary.FLIGHTS' | translate}}</div>
            <ng-container *ngFor="let num of noOfFlightLegs">
              <div class="heading">{{getAirportCity(bookedFlight.legs[num].flightHops[0].from)}} to
                {{getAirportCity(bookedFlight.legs[num].flightHops[bookedFlight.legs[num].flightHops.length-1].to)}}
              </div>

              <div class="row">
                <div class="col-4">
                  {{getDisplayDate(bookedFlight.legs[num].flightHops[bookedFlight.legs[num].flightHops.length-1].ends)
                  | date: 'fullDate'}}</div>

                <div class="col-8">
                  {{'itinerary.TotalTime' | translate}} <strong>{{getFlightDuration(num)?.hrs + 'h ' +
                    getFlightDuration(num)?.mins + 'm'}}</strong>
                  <div class="pipe">|</div>
                  {{'itinerary.Confirmation' | translate}}
                  <strong>#{{bookedFlight.legs[num].confirmationNumber}}</strong>
                </div>
              </div>

              <div class="flight-card-wrapper">
                <div class="flight-card" *ngFor="let flightHop of bookedFlight.legs[num].flightHops; let i=index">
                  <div class="row align-items-center no-gutters">
                    <div class="col-9">
                      <div class="flight-detail-wrapper">
                        <div class="flight-detail">
                          <div class="text-left">
                            <div class="source">{{flightHop.from}}</div>
                            <div class="info">{{getAirportCity(flightHop.from)}}</div>
                            <div class="info">{{flightHop.sourceTerminal ? ('itinerary.Terminal' | translate) +
                              flightHop.sourceTerminal : ''}}</div>
                          </div>

                          <div class="text-center">
                            <span class="icon-plane"></span>
                          </div>

                          <div class="text-right">
                            <div class="destination">{{flightHop.to}}</div>
                            <div class="info">{{getAirportCity(flightHop.to)}}</div>
                            <div class="info">{{flightHop.destinationTerminal ? ('itinerary.Terminal' | translate) +
                              flightHop.destinationTerminal : ''}}</div>
                          </div>
                        </div>

                        <div class="time-detail">
                          <div class="text-left">
                            <div class="time">{{getDisplayDate(flightHop.starts) | date: 'h:mm'}}
                              <small>{{getDisplayDate(flightHop.starts) | date: 'a'}}</small> </div>
                            <div class="info">{{getDisplayDate(flightHop.starts) | date: 'MMMM d, y'}}</div>
                          </div>

                          <div class="text-right">
                            <div class="time">{{getDisplayDate(flightHop.ends) | date: 'h:mm'}}
                              <small>{{getDisplayDate(flightHop.ends) | date: 'a'}}</small> </div>
                            <div class="info">{{getDisplayDate(flightHop.ends) | date: 'MMMM d, y'}}</div>
                          </div>
                        </div>

                        <div class="layover-container" *ngIf="flightLayoverInfoList[num][i]">
                          <span class="layover"><span class="text-primary">{{flightLayoverInfoList[num][i].duration.hrs
                              + 'h ' + flightLayoverInfoList[num][i].duration.mins + 'm'}}</span>
                            {{'itinerary.layoverin' | translate}} {{flightLayoverInfoList[num][i].in}}</span>
                        </div>
                      </div>
                    </div>

                    <div class="col-3">
                      <div class="airline-detail">
                        <div><img class="flight-icon" [src]="getFlightIconURL(flightHop.carrier)"></div>
                        <div>{{flightHop.carrier}} {{flightHop.flightNumber}}</div>

                        <div>{{'itinerary.Operatedby' | translate}}
                          {{getAirlineFullName(flightHop.operatingCarrier)}}</div>
                        <div class="my-4">{{getClassNameAndBrandName(flightHop)}}</div>
                        <div>{{'itinerary.TravelTime' | translate}}</div>
                        <div><strong>{{getDuration(flightHop.duration).hrs + 'h ' + getDuration(flightHop.duration).mins
                            + ' m'}}</strong></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </ng-container>
            <div class="heading">{{'itinerary.FlightBookingDetails' | translate}}</div>
          </div>
          <div *ngIf="hotelShow">
            <div class="card-heading1">{{'itinerary.HOTELS' | translate}}</div>
            <hr>
            <div class="row">
              <div class=" col-8 col-lg-7">
                <div class="text-left text1">{{bookedHotel.hotelName}}</div>
              </div>
              <div class=" col-4 col-lg-5">
                <div *ngIf="confirmaTionHide">
                  <div class="text-right text2">{{'itinerary.Confirmation' | translate}}</div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class=" col-8 col-lg-7">
                <div class="text-left text2">{{bookedHotel.address}}</div>
              </div>
              <div class=" col-4 col-lg-5">
                <div *ngIf="confirmaTionHide">
                  <div class="text-right text1 " style="font-size: 0.75em;">{{confirmationCode}}</div>
                </div>
              </div>
            </div><br>
            <div class="row">
              <div class="col-4 col-lg-3 ">
                <div class="text-left "><span class="text1" style="font-size: 0.75em;">
                    {{bookedHotel.stay}}-{{bookedHotel.stay > 1 ? ('itinerary.Nights' | translate) : ('itinerary.Night'
                    | translate)}} ,</span><span class="text2"> {{noOfRooms}} {{'itinerary.Room' | translate}},
                    {{noOfAdults}} {{noOfAdults > 1 ? ('itinerary.Adults' | translate) :('itinerary.Adult' |
                    translate)}}</span></div>
              </div>
              <div class=" col-8 col-lg-9">
                <div class="text-right text2">{{roomDescription}}
                </div>
              </div>
            </div>
            <div class="hotel-card-wrapper">
              <div class="hotel-card">
                <div class="row">
                  <div class="col-6">
                    <div class="row">
                      <div class="col">
                        <img class="inlineblock_m image" src="assets/images/hotel-icon.png" />
                      </div>
                    </div>
                    <div class="row">
                      <div class="col text1">
                        {{'itinerary.Checkin' | translate}}

                      </div>
                    </div>
                    <div class="row">
                      <div class="col text2" style="font-size:13.6px;">
                        {{getCheckinCheckoutDisplayDate(bookedHotel.checkInDate)}}
                      </div>
                    </div>
                  </div>
                  <div class="col-6">
                    <div class="row">
                      <div class="col">
                        <img class="inlineblock_m image" src="assets/images/hotel-icon.png" />
                      </div>
                    </div>
                    <div class="row">
                      <div class="col text1">
                        {{'itinerary.Checkout' | translate}}

                      </div>
                    </div>
                    <div class="row">
                      <div class="col text2" style="font-size:13.6px;">
                        {{getCheckinCheckoutDisplayDate(bookedHotel.checkOutDate)}}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="text-wrapper" *ngIf="policyShow">
              <div class="text4">{{'itinerary.Cancellationpolicy' | translate}}</div>
              <div class="text4">{{hotelPolicy}}</div>
            </div>
            <hr>
            <div class="heading">{{'itinerary.HotelBookingDetails' | translate}}

            </div>
          </div>
          <div class="booking-card">

            <div class="row align-items-center no-gutters">
              <div class="col-9">
                <div class="booking-detail">
                  <div class="list-item"><strong>{{numOfPassengers > 1 ? numOfPassengers + ('itinerary.Travellers' |
                      translate)
                      : numOfPassengers + ('itinerary.Traveller' | translate)}} </strong></div>

                  <div class="list-item" *ngFor="let passenger of passengers">
                    <div class="row">
                      <div class="col-4">
                        <span>{{passenger.title + ' '}}</span>
                        <span>{{passenger.firstName + ' '}}</span>
                        <span>{{passenger.middleName}}</span>
                        <span>{{' ' + passenger.lastName}}</span>
                      </div>
                      <div class="col-4">{{getPassengerNumber(passenger)}}</div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-3">
                <div *ngIf="getPaymentDetails()" class="payment-detail">
                  <div class="mb-2"><strong>{{getCardName()}}</strong></div>
                  <div>{{getCardBrand()}}</div>
                  <div>xxxx-xxxx-xxxx-{{getCardLast4()}}</div>
                  <div class="price">{{getTotalPrice() | currency}}</div>
                  <div style="display:none;" class="sub-text">{{getPaymentType() | translate }}</div>
                </div>
                <div *ngIf="getPaymentDetailsOfCompany()" class="payment-detail">
                  <div class="mb-2"><strong>{{getLoggedInUserName()}}</strong></div>
                  <div>{{'itinerary.BilledToCompany' | translate}}</div>
                  <div>{{getUserCompanyName()}}</div>
                  <div class="price">{{getTotalPrice() | currency}}</div>
                  <div style="display:none;" class="sub-text">{{getPaymentType() | translate}}</div>
                </div>
              </div>
            </div>
          </div>

          <div class="separator"></div>
          <div *ngIf="!hotelShow">
            <div class="text-center">
              <button class="link-button" (click)="bookAnotherFlight()">{{'itinerary.BOOKANOTHERFLIGHT' |
                translate}}</button>
            </div>
          </div>
          <div *ngIf="hotelShow">
            <div class="text-center book-another">
              <button class="link-button" (click)="backToSearch()">{{'itinerary.BOOKANOTHERHOTEL' | translate}}</button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="itinerary-card" *ngIf="isMobile">
      <div *ngIf="!hotelShow">
        <div class="card-heading">{{'itinerary.FLIGHTS' | translate}}</div>
        <ng-container *ngFor="let num of noOfFlightLegs">
          <div class="d-flex justify-content-between">
            <div class="heading">{{getAirportCity(bookedFlight.legs[num].flightHops[0].from)}} {{'itinerary.to' |
              translate}}
              {{getAirportCity(bookedFlight.legs[num].flightHops[bookedFlight.legs[num].flightHops.length-1].to)}}</div>

            <div class="align-self-end"> {{'itinerary.Confirmation' | translate}}</div>
          </div>

          <div class="d-flex justify-content-between">
            <div>{{getDisplayDate(bookedFlight.legs[num].flightHops[bookedFlight.legs[num].flightHops.length-1].ends)
              | date: 'fullDate'}}</div>

            <div><strong>#{{bookedFlight.legs[num].confirmationNumber}}</strong></div>
          </div>

          <ng-container *ngFor="let flightHop of bookedFlight.legs[num].flightHops; let i=index">
            <div class="detail-header-on-mobile">
              <div class="d-flex">
                <img [src]="getFlightIconURL(flightHop.carrier)" class="flight-icon align-self-start">

                <div>
                  <div>{{getAirlineFullName(flightHop.operatingCarrier)}},</div>
                  <div>{{getClassNameAndBrandName(flightHop)}}</div>
                  <!-- <div>{{getClassName(flightHop.cabinClass)}}</div> -->
                </div>
              </div>

              <div class="operating-carrier-detail">
                <img class="flight-icon" [src]="getFlightIconURL(flightHop.operatingCarrier)">
                <span>{{flightHop.operatingCarrier}} {{flightHop.flightNumber}}</span>
              </div>
            </div>

            <div class="flight-detail-wrapper">
              <div class="flight-detail">
                <div class="text-left">
                  <div class="source">{{flightHop.from}}</div>
                  <div class="info">{{getAirportCity(flightHop.from)}}</div>
                  <div class="info">{{'itinerary.Terminal' | translate}} {{flightHop.sourceTerminal}}</div>
                </div>

                <div class="text-center">
                  <span class="icon-plane"></span>
                  <div><strong>{{getDuration(flightHop.duration).hrs + 'h ' + getDuration(flightHop.duration).mins + '
                      m'}}</strong></div>
                </div>

                <div class="text-right">
                  <div class="destination">{{flightHop.to}}</div>
                  <div class="info">{{getAirportCity(flightHop.to)}}</div>
                  <div class="info">{{'itinerary.Terminal' | translate}} {{flightHop.destinationTerminal}}</div>
                </div>
              </div>

              <div class="time-detail">
                <div class="text-left">
                  <div class="time">{{getDisplayDate(flightHop.starts) | date: 'h:mm'}}
                    <small>{{getDisplayDate(flightHop.starts) | date: 'a'}}</small> </div>
                  <div class="info">{{getDisplayDate(flightHop.starts) | date: 'MMMM d, y'}}</div>
                </div>

                <div class="text-right">
                  <div class="time">{{getDisplayDate(flightHop.ends) | date: 'h:mm'}}
                    <small>{{getDisplayDate(flightHop.ends) | date: 'a'}}</small> </div>
                  <div class="info">{{getDisplayDate(flightHop.ends) | date: 'MMMM d, y'}}</div>
                </div>
              </div>
            </div>

            <div class="layover-container" *ngIf="flightLayoverInfoList[num][i]">
              <span class="layover"><span class="text-primary">{{flightLayoverInfoList[num][i].duration.hrs + 'h ' +
                  flightLayoverInfoList[num][i].duration.mins + 'm'}}</span> {{'itinerary.layoverin' | translate}}
                {{flightLayoverInfoList[num][i].in}}</span>
            </div>
          </ng-container>
        </ng-container>

        <div class="heading">{{'itinerary.FlightBookingDetails' | translate}}</div>
      </div>
      <div *ngIf="hotelShow">
        <div class="card-heading1">{{'itinerary.HOTELS' | translate}}</div>
        <hr>
        <div class="row">
          <div class=" col-8 col-lg-7">
            <div class="text-left text1">{{bookedHotel.hotelName}}</div>
          </div>
          <div class=" col-4 col-lg-5">
            <div *ngIf="confirmaTionHide">
              <div class="text-right text2">{{'itinerary.Confirmation' | translate}}</div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class=" col-8 col-lg-7">
            <div class="text-left text2">{{bookedHotel.address}}</div>
          </div>
          <div class=" col-4 col-lg-5">
            <div *ngIf="confirmaTionHide">
              <div class="text-right text1 " style="font-size: 0.75em;">{{confirmationCode}}</div>
            </div>
          </div>
        </div><br>
        <div class="row">
          <div class="col-4 col-lg-3 ">
            <div class="text-left "><span class="text1" style="font-size: 0.75em;">
                {{bookedHotel.stay}}-{{'itinerary.Night' | translate}}{{bookedHotel.stay > 1 ? 's' :''}} ,</span><span
                class="text2"> {{noOfRooms}} {{'itinerary.Room' | translate}}, {{noOfAdults}} {{'itinerary.Adult' |
                translate}}{{noOfAdults > 1 ? 's' :''}}</span></div>
          </div>
          <div class=" col-8 col-lg-9">
            <div class="text-right text2">{{roomDescription}}
            </div>
          </div>
        </div>
        <div class="hotel-card-wrapper">
          <div class="hotel-card">
            <div class="row">
              <div class="col-6">
                <div class="row">
                  <div class="col">
                    <img class="inlineblock_m image" src="assets/images/hotel-icon.png" />
                  </div>
                </div>
                <div class="row">
                  <div class="col text1">
                    {{'itinerary.Checkin' | translate}}
                  </div>
                </div>
                <div class="row">
                  <div class="col text2" style="font-size:13.6px;">
                    {{bookedHotel.checkInDate | date:'dd/MM/yyyy(EEE)'}}
                  </div>
                </div>
              </div>
              <div class="col-6">
                <div class="row">
                  <div class="col">
                    <img class="inlineblock_m image" src="assets/images/hotel-icon.png" />
                  </div>
                </div>
                <div class="row">
                  <div class="col text1">
                    {{'itinerary.Checkout' | translate}}
                  </div>
                </div>
                <div class="row">
                  <div class="col text2" style="font-size:13.6px;">
                    {{bookedHotel.checkOutDate | date:'dd/MM/yyyy(EEE)'}}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="text-wrapper" *ngIf="policyShow">
          <div class="text4">{{'itinerary.Cancellationpolicy' | translate}}</div>
          <div class="text4">{{hotelPolicy}}</div>
        </div>
        <hr>
        <div class="heading">{{'itinerary.HotelBookingDetails' | translate}}</div>
      </div>
      <div *ngIf="getPaymentDetails()" class="payment-detail">
        <div class="mb-2"><strong>{{getCardName()}}</strong></div>
        <div>{{getCardBrand()}}</div>
        <div>xxxx-xxxx-xxxx-{{getCardLast4()}}</div>
        <div class="price">{{getTotalPrice() | currency}}</div>
        <div style="display:none;" class="sub-text">{{getPaymentType() | translate}}</div>
      </div>
      <div *ngIf="getPaymentDetailsOfCompany()" class="payment-detail">
        <div class="mb-2"><strong>{{getLoggedInUserName()}}</strong></div>
        <div>{{'itinerary.BilledToCompany' | translate}}
        </div>
        <div>{{getUserCompanyName()}}</div>
        <div class="price">{{getTotalPrice() | currency}}</div>
        <div style="display:none;" class="sub-text">{{getPaymentType() | translate}}</div>
      </div>

      <div class="booking-detail">
        <div class="list-item"><strong>{{numOfPassengers > 1 ? numOfPassengers + ('itinerary.Travellers' | translate) :
            numOfPassengers + ('itinerary.Traveller' | translate)}} </strong></div>

        <div class="list-item" *ngFor="let passenger of passengers">
          <div>
            <span>{{passenger.title + ' '}}</span>
            <span>{{passenger.firstName + ' '}}</span>
            <span>{{passenger.middleName}}</span>
            <span>{{' ' + passenger.lastName}}</span>
          </div>
          <div>{{getPassengerNumber(passenger)}}</div>
        </div>
      </div>
      <div *ngIf="!hotelShow">
        <div class="text-center book-another">
          <button class="link-button" (click)="bookAnotherFlight()">{{'itinerary.BOOKANOTHERFLIGHT' |
            translate}}</button>
        </div>
      </div>
      <div *ngIf="hotelShow">
        <div class="text-center book-another">
          <button class="link-button" (click)="backToSearch()">{{'itinerary.BOOKANOTHERHOTEL' | translate}}</button>
        </div>
      </div>
    </div>
  </div>
</div>

<app-navigation></app-navigation>