import { Component, HostListener, OnInit } from '@angular/core';
import { BookingService } from '../booking.service';
import { BookingResponse } from '../entity/booking-response';
import { TravelerDetails } from '../entity/traveler-details';
import { TravelersInfo } from '../entity/travelers-info';
import { FlightResult } from '../entity/flight-result';
import { DateUtils } from '../util/date-utils';
import { DeviceDetailsService } from '../device-details.service';
import { Router, ActivatedRoute } from '@angular/router';
import { CommonUtils } from '../util/common-utils';
import { FlightUtils } from '../util/flight-utils';
import { StringUtils } from '../util/string-utils';
import { Subscription } from 'rxjs';
import { Constants } from '../util/constants';
import { UserAccountService } from '../user-account.service';
import { GallopLocalStorageService } from '../gallop-local-storage.service';
import { HotelBookingResponse } from 'src/app/entity/hotel-booking-response';
import { HotelResult } from '../entity/hotel-result';
import { HotelDetailResult } from '../entity/hotel-detail-result';
import { HotelBookingRequest } from '../entity/hotel-booking-request';
import { TranslateOptionsPipe } from '../util/translate-option';
import { TranslateService } from '@ngx-translate/core';
import { FlightHopResult } from '../entity/flight-hop-result';

@Component({
  selector: 'itinerary',
  templateUrl: './itinerary.component.html',
  styleUrls: ['./itinerary.component.scss']
})
export class ItineraryComponent implements OnInit {

  @HostListener('window:popstate') onpopState() {
    setTimeout(() => {
      this.goToHomePage();
    })
  }

  constructor(private bookingService: BookingService,
    private gallopLocalStorage: GallopLocalStorageService,
    private deviceDetailsService: DeviceDetailsService,
    private userAccountInfoService: UserAccountService,
    private router: Router,
    public translateService: TranslateService,
    private activatedRoute: ActivatedRoute) { }

  bookingResponse: BookingResponse;
  numOfPassengers: number = 1;
  passengerClass: string;
  bookedFlight: FlightResult;
  flightLayoverInfoList: any[] = [];
  noOfFlightLegs: number[] = [0];
  classOptions = Constants.CLASS_OPTIONS;
  passengers;
  bookingResponse1: HotelBookingResponse;
  confirmationNumbers: string[] = [];
  hotelShow = false;
  isMobile: boolean;
  bookedHotel: HotelDetailResult;
  deviceSubscription: Subscription;
  airlines;
  airports;
  roomDescription: string;
  noOfAdults: number;
  noOfRooms: number;
  roomDescriptionName: Array<any>;
  hotelPolicy = '';
  policyShow = true;
  loyalityNo: number;
  pageMode: string;
  confirmaTionHide = false;
  confirmationCode: string;
  ngOnInit() {

    this.deviceSubscription = this.deviceDetailsService.isMobile().subscribe(isMobile => {
      this.isMobile = isMobile;
    });
    this.activatedRoute.queryParams.subscribe(params => {
      if (params['type']) {
        this.pageMode = params['type'];
      }
    });
    if (this.pageMode === 'flight') {
      this.bookingResponse = JSON.parse(this.gallopLocalStorage.getItem("bookingResponse"));
      this.hotelShow = false;
      this.numOfPassengers = this.bookingResponse.travellerDetails.travellers.length;
      this.bookedFlight = this.bookingResponse.bookedFlights.flights[0];
      this.passengerClass = this.bookedFlight.legs[0].flightHops[0].cabinClass;
      this.setFlightLegs(this.bookedFlight.legs.length);
      this.flightLayoverInfoList = FlightUtils.getLegWiseLayoverList(this.bookedFlight);
      this.updateConfirmationNumbers(this.bookedFlight);

      // this.airlines = JSON.parse(localStorage.getItem('airlineNames'));
      // this.airports = JSON.parse(localStorage.getItem('airports'));
      this.airlines = this.bookingResponse.airlineNames;
      this.airports = this.bookingResponse.airports;
      this.passengers = JSON.parse(this.gallopLocalStorage.getItem('passengers')).passengers;
    } else {
      this.bookingResponse1 = JSON.parse(this.gallopLocalStorage.getItem("hotelBookingResponse"));
      this.hotelShow = true;
      this.bookedHotel = this.bookingResponse1.bookedHotels[0];
      if (this.bookingResponse1.bookedHotels[0].reservationCode) {
        this.confirmaTionHide = true;
        this.confirmationCode = this.bookingResponse1.bookedHotels[0].reservationCode;
      } else {
        this.confirmaTionHide = false;
      }
      this.noOfAdults = this.bookingResponse1.travellerDetails.travellers.length;
      this.noOfRooms = this.bookingResponse1.bookedHotels[0].hotelRooms.length;
      this.roomDescription = this.bookingResponse1.bookedHotels[0].hotelRooms[0].hotelRoomName;
      this.roomDescriptionName = this.bookingResponse1.bookedHotels[0].hotelRooms[0].hotelRates[0].cancellationPolicies;
      this.hotelPolicy = this.getHotelPolicy(this.roomDescriptionName);
      if (!this.hotelPolicy) {
        this.policyShow = false;
      } else {
        this.policyShow = true;
      }
      this.numOfPassengers = this.bookingResponse1.travellerDetails.travellers.length;
      this.passengers = JSON.parse(this.gallopLocalStorage.getItem('passengers')).passengers;


    }
  }

  ngOnDestroy() {
    //to be called on navigation change
    // localStorage.removeItem("bookingResponse");
    this.deviceSubscription.unsubscribe();
  }
  getPassengerNumber(x: any): string {
    if (this.pageMode === 'flight') {
      let ktnNumber = '';
      if (x.knownTravellerNumber) {
        ktnNumber = "KTN:" + x.knownTravellerNumber;
        return ktnNumber;
      } else {
        return ktnNumber = '';
      }
    }
    else {
      let loyalityNumber = '';
      if (x.hotelLoyalityInfo.length > 0 && x.hotelLoyalityInfo[0].hotel_loyality_number != null) {
        loyalityNumber = " - " + x.hotelLoyalityInfo[0].hotel_loyality_number;
        return loyalityNumber;
      } else {
        return loyalityNumber = '';
      }
    }
  }
  getPaymentType(): string {
    if (this.pageMode === 'flight') {
      return this.translateService.instant('itinerary.PaidinFull');
    } else {
      if (this.bookedHotel.prepay) {
        return this.translateService.instant('itinerary.PaidinFull');
      } else {
        return this.translateService.instant('itinerary.PayatHotel');
      }

    }
  }
  getCheckinCheckoutDisplayDate(date: Date) {
    return DateUtils.getFormattedDateWithoutTimeZoneFromFormat(date, 'dd/MM/yyyy(EEE)');
  }
  getPaymentDetails(): boolean {
    if (this.pageMode === 'flight') {
      if (!this.bookingResponse.paymentDetails.paymentMethod || this.bookingResponse.paymentDetails.paymentMethod === 'PERSONAL_CARD') {
        return true;
      }
      else {
        return false;
      }
    } else {
      if (!this.bookingResponse1.paymentDetails.paymentMethod || this.bookingResponse1.paymentDetails.paymentMethod === 'PERSONAL_CARD') {
        return true;
      } else {
        return false;
      }
    }
  }
  getPaymentDetailsOfCompany(): boolean {
    if (this.pageMode === 'flight') {
      if (this.bookingResponse.paymentDetails.paymentMethod && this.bookingResponse.paymentDetails.paymentMethod === 'BILL_TO_COMPANY') {
        return true;
      }
      else {
        return false;
      }
    } else {
      if (this.bookingResponse1.paymentDetails.paymentMethod && this.bookingResponse1.paymentDetails.paymentMethod === 'BILL_TO_COMPANY') {
        return true;
      } else {
        return false;
      }
    }
  }
  getCardName(): string {
    if (this.pageMode === 'flight') {
      return this.bookingResponse.paymentDetails.card.name;
    } else {
      return this.bookingResponse1.paymentDetails.card.name;
    }
  }
  getCardBrand(): string {
    if (this.pageMode === 'flight') {
      return this.bookingResponse.paymentDetails.card.brand;
    } else {
      return this.bookingResponse1.paymentDetails.card.brand;
    }
  }
  getCardLast4(): string {
    if (this.pageMode === 'flight') {
      return this.bookingResponse.paymentDetails.card.last4;
    } else {
      return this.bookingResponse1.paymentDetails.card.last4;
    }
  }
  getTotalPrice(): number {
    let serviceFee = 0.0;
    if (this.pageMode === 'flight') {
      serviceFee = this.bookedFlight.serviceFee;
      return (this.bookedFlight.fareBreakup.totalPrice + serviceFee - this.bookingResponse.paymentDetails.appliedGallopCash);
    } else {
      if (this.bookedHotel.serviceFee && this.bookedHotel.serviceFee > 0) {
        serviceFee = this.bookedHotel.serviceFee;
      }
      return (this.bookedHotel.originalPrice + serviceFee - this.bookingResponse1.paymentDetails.appliedGallopCash);
    }
  }
  getHotelPolicy(hotelRoom): string {
    let roomPolicy = '';
    for (let policy of hotelRoom) {
      roomPolicy = roomPolicy + policy.cancellationRule + ',';
    }
    return roomPolicy;
  }
  getTravelerName(traveler: TravelersInfo): string {

    let name = (traveler.title.toUpperCase() == 'MR' || traveler.title.toUpperCase() == 'MRS') ? StringUtils.toTitleCase(traveler.title) + '.' : StringUtils.toTitleCase(traveler.title);

    name += ' ' + traveler.firstName;

    if (traveler.middleName) {
      name += ' ' + traveler.middleName;
    }

    name += ' ' + traveler.lastName;

    return name;

  }

  updateConfirmationNumbers(flight: FlightResult) {
    flight.legs.forEach(leg => {
      let confirmationNum = leg.confirmationNumber;

      if (!CommonUtils.containsObject(confirmationNum, this.confirmationNumbers)) {
        this.confirmationNumbers.push(confirmationNum);
      }
    })
  }

  getFlightDuration(legIndex: number) {
    return FlightUtils.getFlightLegDuration(this.bookedFlight, legIndex);
  }

  setFlightLegs(count) {
    this.noOfFlightLegs = Array(count || 1).fill(1).map((x, i) => i)
  }


  getFlightIconURL(flightCode: string) {
    return this.bookingService.getFlightIconURL(flightCode);
  }

  getAirportName(code: string): string {
    return this.bookingService.getAirportName(code);
  }

  bookAnotherFlight() {
    this.goToHomePage();
  }

  goToHomePage() {
    localStorage.clear();
    this.router.navigate(["search"], { replaceUrl: true });
  }

  getDisplayDate(dateString: string): string {
    return DateUtils.getDisplayDate(dateString);
  }

  getAirlineFullName(code) {
    let airlineFullName = null;
    airlineFullName = this.airlines[code];
    return (airlineFullName === '' || airlineFullName === null) ? code : airlineFullName;
  }

  getAirportCity(code) {
    let airportCity = null;
    airportCity = this.airports[code].name;
    return (airportCity === '' || airportCity === null) ? code : airportCity;
  }
  getClassNameAndBrandName(hop: FlightHopResult) {
    let className = this.getClassName(hop.cabinClass);
    if (hop.brandClass && hop.brandClass.trim().length > 0) {
      className = className + ' (' + hop.brandClass.trim() + ')';
    }
    return className;
  }

  // Todo refactor this
  getClassName(id) {
    let className = null;

    this.classOptions.map(item => {
      if (item.id.toLowerCase() == id.toLowerCase()) {
        className = item.value;
      }
    });

    if (className === null) {
      return id;
    }

    return className;

  }

  getDuration(duration) {
    return DateUtils.getDurationAsHrsMinObj(duration);
  }

  getLoggedInUserName() {
    return this.userAccountInfoService.getUserName();
  }

  getUserCompanyName() {
    return this.userAccountInfoService.getUserCompany();
  }
  backToSearch() {
    this.router.navigate(["search"],
      {
        queryParams:
        {
          type: 'hotel',
        },
      }
    );
  }

}
