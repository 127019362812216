<div class="container1">
  <div class="modal-header" style="background-color:blue !important;">
    <span style="color:#fff !important;"> {{this.source[this.hopIndex].source}}<i class="fa fa-arrow-right"
        style="color:#fff !important;margin-left:6px;margin-right:6px;"></i> {{this.source[this.hopIndex].dest}}</span>
    <button type="button" class="close" data-dismiss="modal" (click)="onModelCancel()">
      <i attr.data-track="seatSelectionCancelled" class="material-icons" style="color:#fff;">close</i>
    </button>
  </div>
  <div *ngIf="this.showSeats" class="modal-body">
    <div class="row">
      <div class="col-2 d-none d-md-block">

      </div>
      <div class="col-12 col-lg-12">
        <div class="seat-row-internal" style="margin-bottom:20px;">
          <div class="col-auto" style="width:50px !important;text-align: center;">
            <i class="fa fa-square-o item" aria-hidden="true"></i>
            <label class="name">{{'seat.OPEN' | translate}}</label>
          </div>
          <div class="col-auto" style="width:50px !important;text-align: center;">
            <i class="fa fa-square-o item6" aria-hidden="true"></i><img class="{{isMobile? 'shape2':'shape'}}"
              src="assets/images/Shape 2.svg">
            <label class="name"> {{'seat.TAKEN' | translate}}</label>
          </div>
          <div class="col-auto" style="width:70px !important;text-align: center;">
            <i class="fa fa-square-o item5" aria-hidden="true"></i>
            <label class="name"> {{'seat.SELECTED' | translate}}</label>
          </div>
          <div class="col-auto" style="width:70px !important;text-align: center;">
            <i class="fa fa-square-o item1" aria-hidden="true"></i>
            <label class="name"> {{'seat.PREMIUM' | translate}}</label>
          </div>
          <div class="col-auto" style="width:50px !important;text-align: center;">
            <i class="fa fa-square-o item2" aria-hidden="true"></i>
            <label class="name"> {{'seat.EXIT' | translate}}</label>
          </div>
          <div class="col-auto" style="width:50px !important;text-align: center;">
            <i class="fa fa-square-o item3" aria-hidden="true"></i>
            <label class="name">{{'seat.WINGS' | translate}}</label>
          </div>
        </div>
        <hr>
        <div *ngIf="this.seatData">
          <div *ngFor="let item of this.seatData[this.index];let i=index"
            class="{{item.characteristic && haveExitRow(item.characteristic) ? 'filter-row2':'filter-row1'}}">
            <div *ngIf="item.characteristic && !haveWings(item.characteristic)" class="wingsNotPresent">

            </div>
            <div *ngIf="item.characteristic && haveWings(item.characteristic)" class="wingsPresent">
              <i class="fa fa-square-o item4" aria-hidden="true"></i>
            </div>
            <label class="rowNo">{{item.rowCode}}</label>
            <div class="seat-row-internal">
              <div *ngFor="let seat of item.row;let j=index">
                <div *ngIf="seat.type==='Seat' && seat.available==='BLOCKED'" class="col-auto">
                  <i class="fa fa-square-o item6" aria-hidden="true"></i><img class="shape"
                    src="assets/images/Shape 2.svg">
                </div>
                <div *ngIf="seat.type==='Seat' && seat.available==='OCCUPIED'" class="col-auto">
                  <i class="fa fa-square-o item6" aria-hidden="true"></i><img class="shape"
                    src="assets/images/Shape 2.svg">
                </div>
                <div *ngIf="seat.type==='Seat' && seat.available==='RESERVED'" class="col-auto">
                  <i class="fa fa-square-o item6" aria-hidden="true"></i><img class="shape"
                    src="assets/images/Shape 2.svg">
                </div>
                <div *ngIf="seat.type==='Seat' && seat.available==='NO_SEAT'" class="col-auto">
                  <i class="fa fa-square-o item6" aria-hidden="true"></i><img class="shape"
                    src="assets/images/Shape 2.svg">
                </div>
                <div *ngIf="seat.type==='Seat' && seat.available===null" class="col-auto">
                  <i class="fa fa-square-o item6" aria-hidden="true"></i><img class="shape"
                    src="assets/images/Shape 2.svg">
                </div>
                <div
                  *ngIf="seat.type==='Seat' && seat.available==='AVAILABLE'&& getSeatSelected(seat.seatCode)===undefined"
                  class="col-auto">
                  <i class="fa fa-square-o item" aria-hidden="true" (click)="selectSeat(seat.seatCode)"></i>
                </div>
                <div
                  *ngIf="seat.type==='Seat' && seat.available==='Premium' && getSeatSelected(seat.seatCode)===undefined"
                  class="col-auto">
                  <i class="fa fa-square-o item1" aria-hidden="true" (click)="selectSeat(seat.seatCode)"></i>
                </div>
                <div *ngIf="getSeatSelected(seat.seatCode)===seat.seatCode" class="col-auto">
                  <i class="fa fa-square-o item5" aria-hidden="true" (click)="selectSeat(seat.seatCode)"></i><span
                    class="name1" (click)="selectSeat(seat.seatCode)">{{getSeatCodeToDisplay(seat.seatCode)}}</span>
                </div>
                <div *ngIf="seat.type==='Aisle'" class="col-auto" style="width:21px;">

                </div>
              </div>
            </div>
            <div *ngIf="item.characteristic && !haveWings(item.characteristic)" class="wingsNotPresent1">

            </div>
            <div *ngIf="item.characteristic && haveWings(item.characteristic)" class="wingsPresent1">
              <i class="fa fa-square-o item4" aria-hidden="true"></i>
            </div>
          </div>
        </div>
      </div>
      <div class="col-2 d-none d-md-block">

      </div>
    </div>
    <div *ngIf="(this.seatData && this.seatData.length >0)&& this.totalFreeSeat" style="margin-top:40px;">
      <div class="text-center" style="margin-bottom:50px !important;margin-left:0px;">
        <button *ngIf="this.index > 0" class="btn btn-secondary2" style="margin-right:10px !important;"
          (click)="selectIndexDecrease()"><span class="add2"> {{'seat.back' | translate}}</span></button>
        <button *ngIf="this.hopIndex !== (this.source.length -1)" class="btn btn-secondary1" (click)="selectIndex()"
          [disabled]="(this.selectedSeatArray[this.index].length !== this.bookingService.travellers && this.selectedSeatArray[this.index].length < this.totalFreeSeat[index])"><span
            class="add2">{{'seat.Next' | translate}}:{{'seat.Selectseatsfor' | translate}} {{getSourceName()}} -> {{getDestName()}}</span></button>
        <button
          *ngIf="(this.selectedSeatArray[this.index].length !== this.bookingService.travellers && this.selectedSeatArray[this.index].length < this.totalFreeSeat[index]) && (this.hopIndex !== (this.source.length -1))"
          class="btn btn-secondary2" style="margin-right:6px;margin-left:6px;width:70px !important;"
          (click)="selectSkip()"><span class="add2">{{'seat.skip' | translate}}</span></button>
        <!-- <button  *ngIf="this.source.length > (this.seatData.length -1)" class="btn btn-secondary1" (click)="selectIndex()"><span class="add2">next</span></button>
         --> <button *ngIf="this.hopIndex === (this.source.length -1)" class="btn btn-secondary"
          attr.data-track="seatSelectionDone" (click)="proceedForPayment()"><span class="add"
            attr.data-track="seatSelectionDone"><span
              *ngIf="(this.selectedSeatArray[this.index].length !== this.bookingService.travellers && this.selectedSeatArray[this.index].length < this.totalFreeSeat[index])">skip
              & </span>{{'seat.PROCEEDTOPAY' | translate}}: {{this.bookingService.total | currency : getCurrencySymbol(this.bookingService.currencyCode) : "code"
            : '1.2-2' }}</span></button>
      </div>
    </div>
  </div>
  <div *ngIf="!this.showSeats" class="modal-body">
    <div class="text-center" style="margin-bottom:40px !important;margin-top:40px !important;"> {{'seat.Nofreeseatsavailable' | translate}} </div>
    <div class="text-center" style="margin-bottom:50px !important;">
      <button *ngIf="this.hopIndex > 0" class="btn btn-secondary1" style="margin-right:10px !important;"
        (click)="selectIndexDecrease()"><span class="add2">{{'seat.back' | translate}</span></button>
      <button *ngIf="this.hopIndex !== (this.source.length -1)" class="btn btn-secondary1"
        (click)="selectIndexForSeat()"><span class="add2">{{'seat.Next' | translate}}: {{'seat.Selectseatsfor' | translate}} {{this.source[this.hopIndex].source}}
          -> {{this.source[this.hopIndex].dest}}</span></button>
      <button *ngIf="this.hopIndex === (this.source.length -1)" class="btn btn-secondary"
        (click)="proceedForPayment()"><span class="add">{{'seat.PROCEEDTOPAY' | translate}}</span></button>
    </div>
  </div>

</div>