import { JsonValue, IDynamicObject, JsonValueObject, JsonValueArray, IParseOptions } from "../types";
import { objectDefinitions, getTypedInheritanceChain, ObjectDefinition } from "../classes/object-definition";
import { PropertyDefinition } from "../classes/property-definition";
import { propertyConverters } from "../converters";
import { KeyValue } from "../decorators";

export function deserialize(object: JsonValue, type?: Function, options: IParseOptions = { runConstructor: false }): any {
    if (object && object.constructor === Array) {
        return (object as JsonValueArray).map(o => deserializeRootObject(o, type, options));
    }

    return deserializeRootObject(object, type, options);
}

function deserializeRootObject(object: JsonValue, objectType: Function = Object, options: IParseOptions): any {
    if (!objectDefinitions.has(objectType)) {
        return object;
    }

    const values = object as JsonValueObject;

    const [type, ...superTypes] = getTypedInheritanceChain(objectType, values);

    const output = Object.create(type.prototype);

    const definitions = [...superTypes.reverse(), type].map(t => objectDefinitions.get(t)).filter(t => !!t) as ObjectDefinition[];

    const valuesCopy = Object.assign({}, values); // To keep remaining keys

    definitions.forEach(d => {
        if (options.runConstructor) {
            d.ctr.call(output);
        }

        d.beforeDeserialized.call(output);

        d.properties.forEach((p, key) => {
            if (!p.type) {
                throw new Error(`Cannot deserialize property '${key}' without type!`);
            }

            const value = values[p.serializedName];

            delete valuesCopy[p.serializedName];

            if (value == undefined || p.readonly) {
                return;
            }

            if (p.type === Array || p.type === Set) {
                output[key] = deserializeArray(value, p, options);
                if (p.type === Set) {
                    output[key] = new Set<any>(output[key]);
                }
                return;
            }

            output[key] = deserializeObject(value, p, options);
        });

        Object.assign(output, valuesCopy);

        for (let key in valuesCopy) {
            delete valuesCopy[key];
        }

        d.onDeserialized.call(output);
    });

    return output;
}

function deserializeArray(array: JsonValue, definition: PropertyDefinition, options: IParseOptions): IDynamicObject {
    return (array as JsonValueArray).map(v => deserialize(v, definition.elementType, options));
}

function deserializeKeyValue(object: JsonValue, definition: PropertyDefinition, options: IParseOptions): IDynamicObject {
    let map = new KeyValue();
    Object.keys(object).forEach(key => {
        map[key] = deserialize(object[key], definition.elementType, options);
    });
    return map;
}

function deserializeObject(object: JsonValue, definition: PropertyDefinition, options: IParseOptions): IDynamicObject {
    const primitive = definition.type === String || definition.type === Boolean || definition.type === Number;
    const value: any = object;

    const converter = definition.converter || propertyConverters.get(definition.type);
    if (converter) {
        return converter.deserialize(value);
    }

    if (!primitive) {
        if (definition.type === KeyValue) {
            return deserializeKeyValue(value, definition, options);
        }
        const objDefinition = objectDefinitions.get(definition.type);

        if (objDefinition) {
            return deserialize(value, definition.type);
        }
    }

    return value;
}
