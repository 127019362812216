<!-- <button (click)="back()">Back</button> -->
<div class="container">
  <div class="card">
    <div class="payment-header">
      <span class="heading">{{'payment.Payment' | translate}}</span>
      <span class="price">{{transactionAmount | currency:'USD'}}</span>
      <span class="icon-customer"></span>
      <span class="passengers">X{{noOfPassengers}}</span>
    </div>
    <form class="form" [formGroup]="checkoutForm">
      <div class="content-wrapper">
        <input placeholder="{{'payment.Nameoncard' | translate}}" formControlName="name" class="name-card">
        <div class="error-color"
          *ngIf="checkoutForm.get('name').invalid && (checkoutForm.get('name').dirty || checkoutForm.get('name').touched)">
          <div class="error-color" *ngIf="checkoutForm.get('name').errors['required']">{{'payment.Nameisrequired' |
            translate}}</div>
          <div class="error-color" *ngIf="checkoutForm.get('name').errors['pattern']">{{'payment.Pleaseenteravalidname'
            | translate}}</div>

        </div>
      </div>
      <div>

        <div class="cell example example2">
        </div>

        <div class="field half-width">
          <div id="card" #card></div>
          <div class="baseline"></div>
        </div>

        <div class="error-align">

          <div class="error-color" *ngIf="checkoutForm.get('card').invalid && checkoutForm.get('card').touched">
            <div class="error-color" *ngIf="checkoutForm.get('card').errors['required']">{{'payment.Cardisrequired' |
              translate}}</div>
            <div class="error-color" *ngIf="checkoutForm.get('card').errors['empty']">{{'payment.Cardisrequired' |
              translate}}</div>
            <div class="error-color" *ngIf="checkoutForm.get('card').errors['stripeError']">{{
              checkoutForm.get('card').errors['stripeError'] }}</div>
          </div>
        </div>
      </div>
      <div class="row ">
        <div class="col-sm-12 button-wrapper">
          <button type="submit" class="button" [disabled]="disablePay" (click)="onSubmit()">
            <span class="icon-locked"></span> &nbsp;{{'payment.PAY' | translate}} {{transactionAmount | currency:'USD'}}
          </button>
        </div>
      </div>

    </form>
  </div>

</div>