<div #modal class=" show" id="">
  <div class="modal-dialog ">
    <div class="modal-content">
      <div class="modal-header">
        <span class="icon-search-from modal-search-from"></span>

      </div>
      <div class="modal-body">


        <h4 class="modal-title title-msg">Your session has expired.</h4>
        <div class="email-text-content">
        </div>


        <div class="modal-button-wrapper">
          <button type="button" name="buttonName" class="btn btn-default modal-button" (click)="onConfirm()">OK</button>
        </div>

      </div>


    </div>
  </div>
</div>