import { Injectable } from "@angular/core";

@Injectable()
export class ClientConfiguration {
    public status = 'success';
    public errorMessage = '';
    public responseType = 'ShowNothing';
    public ctaText = null;
    public cta = null;
    public carsEnabled = true;
    public locationSupportedInCarSearch = true;
    public presentAlternateInPolicyOptions = true;
    public dutyOfCareEnabled = true;
    public classicReports = false;
    public bookingAppBaseUrl;
    public dashboardAppBaseUrl;
    public enableReconcile = false;
    public agentEmailId;
    public brandName;
}