<div class="feature1">
  <span><img *ngIf="!this.userAccountService.historyFeedBack || this.feedback==='UP'" class="bed-image"
      style="cursor: pointer;" (click)="getUrl()" src="{{this.url}}"></span> <span><img
      *ngIf="!this.userAccountService.historyFeedBack || this.feedback==='DOWN'" class="bed-image"
      style="cursor: pointer;" (click)="getUrl1()" src="{{this.url1}}"></span>
</div>
<div *ngIf="this.dislike" class="feature">
  <h4 style="font-weight: bold;margin-bottom:10px; text-align: left;padding-left: 5px;;"> {{'feedback.Whatwentwrong' | translate}}</h4>
</div>
<div *ngIf="this.dislike" class="feature">
  <div class="filter-row">
    <div class="col {{ isSelected('LOCATION') ? 'negative-reason-selected':'negative-reason'}}"
      (click)="getSelectReason('LOCATION')">
      <div class="box11"> {{'feedback.Location' | translate}}</div>
    </div>
    <div class="col {{ isSelected('APP') ? 'negative-reason-selected':'negative-reason'}}"
      (click)="getSelectReason('APP')">
      <div class="box11"> {{'feedback.Bookingapp' | translate}}</div>
    </div>
  </div>
  <div class="filter-row">
    <div class="col {{ isSelected('CHECKIN') ? 'negative-reason-selected':'negative-reason'}}"
      (click)="getSelectReason('CHECKIN')">
      <div class="box11"> {{'feedback.Hotelcheck-in' | translate}}</div>
    </div>
    <div class="col {{ isSelected('CLEANLINESS') ? 'negative-reason-selected':'negative-reason'}}"
      (click)="getSelectReason('CLEANLINESS')">
      <div class="box11"> {{'feedback.Cleanliness' | translate}}</div>
    </div>
  </div>
  <div class="filter-row" style="text-align: center;">
    <div class="col {{ isSelected('SERVICE') ? 'negative-reason-selected':'negative-reason'}}"
      (click)="getSelectReason('SERVICE')">
      <div class="box11">{{getBrandName()}}  {{'feedback.Service' | translate}}</div>
    </div>
  </div>
</div>
<div *ngIf="!this.userAccountService.historyFeedBack" class="modal-button-wrapper"
  style="margin-top: 8px;text-align: center;">
  <button type="button" name="buttonName" class="btn btn-secondary" [disabled]="this.feedback==='NONE'"
    (click)="acceptClick(true)"><span class="add">{{buttonText}}</span></button>
</div>