import { PipeTransform, OnDestroy, Pipe, NgModule } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { Observable } from "rxjs";
// @NgModule CommonModule
@Pipe({
  name: 'translateOptions',
})
export class TranslateOptionsPipe implements PipeTransform, OnDestroy {

  constructor(public translateService: TranslateService) { }

  transform(items: Array<any>): Array<any> {

    if (!items) return items;
    let returnItems = JSON.parse(JSON.stringify(items));
    for (let item of returnItems) {
      if (item.value && item.value.indexOf('ngOption') == 0)
        item.value = this.translateService.instant(item.value);
    }

    return returnItems;
  }
  ngOnDestroy() {
  }
}