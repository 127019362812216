<ng-container *ngIf="!njoySpecificBuild">
  <div class="container3"
    style="display:inline-block; text-align:center !important; height:100px; position: absolute;bottom: 0;width: 100%;"
    [ngStyle]="{'background-color': isDashboardPage() ?'#fff':'#EDFAFC'}">
    <div class="footer-logo" style="text-align:center !important;">
      <span>
        <img class="image" src="assets/images/footer-logo.png">
      </span>
    </div>
    <div class="footer-navigation">
      <ul style="text-align: center !important;margin-bottom: 0px !important;">
        <li>
          <a href="https://routespring.com/"> {{ 'footer1.Home' | translate }}</a>
        </li>
        <li>
          <a href="https://routespring.com/privacy.html" target="_blank"> {{ 'footer1.Privacy' | translate }}</a>
        </li>
        <span>
          <a href="https://routespring.com/tos.html" target="_blank"> {{ 'footer1.Termsofservice' | translate }}</a>
        </span>
      </ul>
    </div>
  </div>
</ng-container>