<!-- <ng-template #hotelModal let-modal>     -->
<div *ngIf="!njoySpecificBuild">
    <div #hotelModal class="modal-body" style="height: 150px;margin-top: 30px;">
       
        <div class="row" class="DetailsBoxLoader" style="position: relative;">
          <app-loader
             style="text-align:center;margin-left:  auto !important;margin-right: auto !important;"
             [spinnerStyle]="true"></app-loader>
       </div>
      
       <div  style="text-align: center;margin:auto;margin-top: 30px;">
          {{'result.FetchingyourCarspleasewait' | translate}}
         </div>
       </div>

</div>
<!-- </ng-template>
        
        <div class="modal-button">
            <button (click)="showModal(hotelModal)">Launch Modal</button>
        </div> -->
<div *ngIf="njoySpecificBuild">
    <div #hotelModal class="modal-body" style="height: 150px;margin-top: 30px;">
       
        <div class="row" class="DetailsBoxLoader" style="position: relative;">
          <app-loader
             style="text-align:center;margin-left:  auto !important;margin-right: auto !important;"
             [spinnerStyle]="true"></app-loader>
       </div>
      
       <div  style="text-align: center;margin:auto;margin-top: 30px;">
          {{'result.FetchingyourCarspleasewait' | translate}}
         </div>
       </div>

</div>