export class HotelFilterDTO {
  public currentSortOptionId = 'recommended';
  public filter_hotelChains: string[] = [];
  public filter_starRating = 0;
  public filter_amenities = [];
  public filter_policy = 'NONE';
  public isRated = 0;
  public filter_hotelName = '';
  public appliedFilterList = [];
  public isRatingAny = true;

  public temp_filter_hotelChains: string[] = [];
  public temp_filter_starRating = 0;
  public temp_filter_amenities: string[] = [];
  public temp_filter_policy = 'NONE';
}

