import { PassportDetails } from './passport-details';
import { FrequentFlyerInfo } from './frequent-flyer-info';
import { JsonObject } from '../util/ta-json/src/decorators/json-object';
import { JsonProperty } from '../util/ta-json/src/decorators/json-property';
import { PhoneNumberDTO } from './phonenumber-dto';

@JsonObject()
export class TravelersInfo {

  title: string;
  firstName: string;
  lastName: string;
  middleName: string;
  gender: string;
  dateOfBirth: string;
  phoneNumber: string;
  nationality: string;
  address: string;
  zipCode: string;
  email: string;
  passportDetails: PassportDetails;
  knownTravellerNumber: string;
  frequentFlyerInfo: FrequentFlyerInfo[];
  hotelLoyalityInfo: Array<any>;
  carLoyalityInfo: Array<any>;
  roomId: string;
  seat: string;

  public constructor(fields?: any) {

    if (fields) {
      Object.assign(this, fields);
    }
  }
}
