<div class="switch-toggle switch-3 switch-candy {{toggleSwitchClassName}}">
        <input *ngIf="selectedOption === leftOptionValue" #leftOption class="switch-toggle-input"
                id="leftOption{{switchGroupName}}" value="{{leftOptionValue}}" name="{{switchGroupName}}" type="radio"
                checked="true">
        <input *ngIf="selectedOption !== leftOptionValue" #leftOption class="switch-toggle-input"
                id="leftOption{{switchGroupName}}" value="{{leftOptionValue}}" name="{{switchGroupName}}" type="radio">
        <label class="switch-toggle-label {{(selectedOption === leftOptionValue)?toggleSwitchLabelClassName:''}}"
                for="leftOption{{switchGroupName}}" (click)="setSelection(leftOptionValue)">{{leftOptionText}}</label>
        <input *ngIf="selectedOption === rightOptionValue" #rightOption class="switch-toggle-input"
                id="rightOption{{switchGroupName}}" value="{{rightOptionValue}}" name="{{switchGroupName}}" type="radio"
                checked="true">
        <input *ngIf="selectedOption !== rightOptionValue" #rightOption class="switch-toggle-input"
                id="rightOption{{switchGroupName}}" value="{{rightOptionValue}}" name="{{switchGroupName}}"
                type="radio">
        <label class="switch-toggle-label {{(selectedOption === rightOptionValue)?toggleSwitchLabelClassName:''}}"
                for="rightOption{{switchGroupName}}"
                (click)="setSelection(rightOptionValue)">{{rightOptionText}}</label>
        <a></a>
</div>