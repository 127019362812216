
export class CarSearchQueryParam {
  public pickUp: string;
  public dropOff: string;
  public pickUpLat: number;
  public pickUpLng: number;
  public dropOffLat: number;
  public dropOffLng: number;
  public sameAddress: boolean;
  public carType: string;
  public carRentalCompany: string;
  public pickTime: string;
  public travellerEmail: string;
  public dropTime: string;
  public pickUpDate: string;
  public dropOffDate: string;
  public dropOffType: string;
  public pickupType: string;
}
