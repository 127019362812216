export enum NoOfStops {
  ANY = "10",
  NONE = "0",
  ONE = "1",
  TWO = "2",
  THREE = "3",
  FOUR = "4",
  FIVE = "5",

}
