<div #modal class="show" id="deleteConfirm" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
    <div class="table-view">
        <div class="table-cell-view">
            <div class="modal-dialog modal-dialog-md" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="myModalLabel">
                            <img *ngIf="!isAmnetRelease()" src="assets/images/footer-logo.png" alt="logo" />
                        </h5>
                        <button *ngIf="!disableCancel && !login" type="button" class="close" data-dismiss="modal"
                            (click)="onCancel()">
                            <i class="material-icons">close</i>
                        </button>
                    </div>
                    <div class="modal-body" *ngIf="!this.dashboard && !flightChart">
                        <div class="modal-content-heading">
                            <h3></h3>
                            <h3 [style.color]="color">{{title}} {{index}}</h3>
                        </div>
                        <div class="modal-content-text modal-content-width">
                            <p [style.color]="color" style="max-width: 333px;">{{message}}</p>
                        </div>
                        <div *ngIf="!this.profile" class="modal-content-button delete-modal-button">
                            <button class="text-button link-primary button1" type="button" [style.color]="buttonColor"
                                (click)="onConfirm()">
                                {{yesButtonSubText}}</button><span
                                *ngIf="this.deleteCard && this.userAccountInfoService.deletingCard" class="loaderClass">
                                <loader-dots class="loaderAlign"></loader-dots>
                            </span>
                            <button *ngIf="!disableCancel && !login" class="text-button link-primary button2"
                                [style.color]="buttonColor" (click)="onCancel()" data-dismiss="modal">{{noButtonSubText
                                ? noButtonSubText :('paymentDetails.cancel' | translate)}}</button>
                        </div>
                        <button *ngIf="this.profile" class="btn btn-secondary" (click)="onConfirm()"><span
                                class="add">{{'emailBookingFlow.YES' | translate }}</span></button>
                        <button *ngIf="this.profile" class="btn btn-normal" (click)="onCancel()"><span
                                class="add1">{{'emailBookingFlow.NO' | translate }}</span></button>
                    </div>
                    <div class="modal-body" *ngIf="this.dashboard">
                        <div class="modal-content-heading">
                            <h3></h3>
                            <h3>{{title | translate}}</h3>
                        </div>
                        <div class="modal-content-text modal-content-width">
                            <p [style.color]="color" style="max-width: 333px;">{{message}}</p>
                        </div>
                    </div>
                    <div class="modal-body" *ngIf="flightChart">
                            <div class="modal-content-heading">
                                <h3></h3>
                                <h3>{{title | translate}}</h3>
                            </div>
                            <div class="modal-content-text modal-content-width">
                                <div  style="color:#6C6865;text-align: left;line-height: 26px;"[innerHTML]="message"></div>
                            </div>
                        </div>
                </div>
            </div>
        </div>
    </div>
</div>