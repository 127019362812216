<div #modal class="show" id="confirmBookingModal" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
    <div class="table-view">
        <div class="table-cell-view">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="myModalLabel">
                            <img src="assets/images/footer-logo.png" alt="logo" />
                        </h5>
                        <button style="cursor: pointer; z-index: 2;" *ngIf="isCrossVisible()" type="button"
                            class="close" data-dismiss="modal" (click)="hide()">
                            <i class="material-icons">close</i>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="modal-icon-container">
                            <span class="modal-icon">
                                <i class="material-icons">check</i>
                            </span>
                        </div>
                        <div class="modal-content-heading">
                            <h3>{{message}}</h3>
                        </div>
                        <div *ngIf="isCTAButtonVisible()" class="modal-button-wrapper">
                            <button type="button" name="buttonName" (click)="ctaButtonAction()"
                                class="btn btn-default modal-button">{{getCTAButtonText()}}</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>