import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { BookingResponse } from '../entity/booking-response';
import { GallopLocalStorageService } from '../gallop-local-storage.service';
import { HotelBookingResponse } from 'src/app/entity/hotel-booking-response';
import { HotelBookingRequest } from '../entity/hotel-booking-request';
import { BookingService } from '../booking.service';
@Injectable()
export class ItineraryGuard implements CanActivate {

  constructor(private bookingService: BookingService,
    private router: Router,
    private gallopLocalStorage: GallopLocalStorageService,
  ) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    let bookingResponse1: HotelBookingResponse = JSON.parse(this.gallopLocalStorage.getItem("hotelBookingResponse"));
    let bookingResponse: BookingResponse = JSON.parse(this.gallopLocalStorage.getItem("bookingResponse"));
    if (bookingResponse && bookingResponse.bookedFlights) {
      return true;
    }
    else if (bookingResponse1 && bookingResponse1.bookedHotels) {
      return true;
    }
    else {
      this.router.navigate(["search"], { replaceUrl: true });
      return false;
    }
  }
}
