import { getDefinition } from "../classes/object-definition";

// tslint:disable:ext-variable-name only-arrow-functions

export class KeyValue<T> {
    [index: string]: T
}

export function JsonKeyValue(elementType?: Function): PropertyDecorator {
    return function (target: any, key: string): void {
        const property = getDefinition(target.constructor).getProperty(key);

        property.type = KeyValue;
        property.elementType = elementType;
    };
}
