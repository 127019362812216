import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { ModalDirective, BsModalRef } from 'ngx-bootstrap/modal';
import { DateUtils } from '../util/date-utils';
import { IfStmt } from '@angular/compiler';
import { UserAccountService } from '../user-account.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-feedback',
  templateUrl: './feedback.component.html',
  styleUrls: ['./feedback.component.scss']
})
export class FeedbackComponent implements OnInit {
  @ViewChild('modal', { static: true }) modal: ModalDirective;

  @Input() hotelName: string;
  @Input() checkin: any;
  @Input() checkout: any;
  @Input() ticketId: string;
  @Input() eventId: any;
  @Input() tripId: any;
  @Input() transactionId: any;
  success = false;
  selectReasoon = [];
  feedback = 'NONE';

  constructor(public bsModalHotelreview: BsModalRef,
    private translate: TranslateService,
    public userAccountService: UserAccountService,) { }

  ngOnInit() {
  }

  acceptClick(showSuccess) {
    if (this.userAccountService.feedbackSubmitSuccessfully) {
      this.hide();
    } else {
      this.userAccountService.hotelFeedBack(this.tripId, this.ticketId, this.transactionId, this.eventId, this.feedback, this.selectReasoon).subscribe(resp => {
        if (resp.status === 'success' && showSuccess) {
          this.success = true;
          this.userAccountService.feedbackSubmitSuccessfully = true;
        }
      });
      this.hide();
      return;
    }


  }
  hide() {
    this.bsModalHotelreview.hide();
  }

  getDisplayDate(dateString, format: string): string {
    return DateUtils.getFormattedDateWithoutTimeZoneFromFormat(new Date(dateString), format);

  }

}
