import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { BsModalService, BsModalRef, ModalDirective } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';
@Component({
  selector: 'app-cancel-success',
  templateUrl: './cancel-success.component.html',
  styleUrls: ['./cancel-success.component.scss']
})
export class CancelSuccessComponent implements OnInit {
  @ViewChild('modal', { static: true }) modal: ModalDirective;
  constructor(private modalService: BsModalService,
    public bsModalRef: BsModalRef) { }
  @Input() message: string;
  @Input() message2: string;
  public acceptClickSubject = new Subject();
  ngOnInit() {
  }
  public onCancel(): void {
    this.acceptClickSubject.next(true);
    this.bsModalRef.hide();
    this.acceptClickSubject.next(false);
  }
  hide() {
    this.bsModalRef.hide();
  }
}
