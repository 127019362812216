<div>
    <div class="card-div shadow1" [ngStyle]="changeStyle()">
        <h1 style="margin-bottom:15px;margin-top:10px;font-weight:bold">{{ 'help.Support' | translate }}</h1>
        <span class="help">  {{ 'help.Werehereforyou' | translate }}</span>
        <div
            style="margin-right:2px !important;margin-left:2px !important;margin-bottom:10px !important;margin-top:10px !important;">
            <ng-container>
                <div class="support-item">
                    <a href="{{'tel:'+this.getPhoneNo()}}" data-rel="external"
                        style="width: 100%;display:inline-block;"><button style="float:left;"
                            class="btn button-normal"><i class="fa fa-phone" aria-hidden="true"
                                style="font-size:22px;color:blue !important;"></i><br><span
                                class="view">  {{ 'support.Phone' | translate }}</span></button>
                        <div class="support-item-detail"> {{getPhoneNo()}}</div>
                    </a>
                </div>
                <div class="support-item">
                    <a [href]="contactNumber | safeurl: 'sms:'"  style="width: 100%;display:inline-block;"> <button
                            class="btn button-normal" style="float:left;"><i class="fa fa-comment" aria-hidden="true"
                                style="font-size:22px;color:blue !important;"></i><br><span
                                class="view"> {{ 'support.Text' | translate }}</span></button>
                        <div class="support-item-detail"> {{getPhoneNo()}}</div>
                    </a>
                </div>
                <div class="support-item">
                    <a href="{{'mailto:'+ getEmailId()}}" data-rel="external"
                        style="width: 100%;display:inline-block;text-transform: lowercase;"> <button style="float:left;"
                            class="btn button-normal"><i class="fa fa-envelope-o" aria-hidden="true"
                                style="font-size:22px;color:blue !important;"></i><br><span
                                class="view"> {{ 'support.Email' | translate }}</span></button>
                        <div class="support-item-detail"> {{getEmailId()}}</div>
                    </a>
                </div>

            </ng-container>
        </div>
    </div>
</div>
<app-navigation></app-navigation>