import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { BsModalService, BsModalRef, ModalDirective } from 'ngx-bootstrap/modal';
import { environment } from 'src/environments/environment';
import { UserAccountService } from '../user-account.service';
@Component({
  selector: 'init-client-component',
  templateUrl: './init-client-component.html',
  styleUrls: ['./init-client-component.scss']
})
export class InitClientComponent implements OnInit {
  @ViewChild('modal', { static: true }) modal: ModalDirective;
  constructor(private modalService: BsModalService,
    private userAccountService: UserAccountService,
    public bsModalRef: BsModalRef) { }
  njoySpecificBuild: boolean;
  @Input() message: string;
  @Input() responseType: string;
  @Input() cta: string;
  @Input() ctaText: string;
  ngOnInit() {
    this.njoySpecificBuild = this.userAccountService.isItNjoyBuild();
    if (this.cta && this.cta.trim().length > 0 && this.cta.indexOf('?') === -1) {
      this.cta = this.cta + '?dummy=/TripItAPI/';
    } else {
      this.cta = this.cta + '&dummy=/TripItAPI/';
    }
  }
  hide() {
    this.bsModalRef.hide();
  }

  isCTAButtonVisible() {
    return (this.responseType === 'AllowedToProceed'
      || this.responseType === 'AllowedToProceedWithCTA'
      || this.responseType === 'NotAllowedToProceedWithCTA');
  }
  getCTAButtonText() {
    if (this.responseType === 'AllowedToProceed') {
      return 'OK';
    }
    return this.ctaText;
  }
  ctaButtonAction() {
    if (this.responseType !== 'AllowedToProceed') {
      if (this.njoySpecificBuild) {
        window.location.href = this.cta;
      } else {
        window.open(this.cta);
      }
    }
    if (this.responseType === 'AllowedToProceed' || this.responseType === 'AllowedToProceedWithCTA') {
      this.hide();
    }
  }
  isCrossVisible() {
    if (this.responseType === 'AllowedToProceed' || this.responseType === 'AllowedToProceedWithCTA') {
      return true;
    }
    return false;
  }
} 