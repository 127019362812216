import { AbstractControl, FormArray, FormGroup, NG_VALIDATORS, ValidationErrors, Validator, ValidatorFn } from '@angular/forms';
import { Directive } from '@angular/core';


export const sourceDestinationValidator: ValidatorFn =
  (control: FormGroup): ValidationErrors | null => {

    const source = control.get('source');
    const destination = control.get('destination');

    var isValid: boolean = true;

    if (source.value && destination.value && source.value.trim() == destination.value.trim()) {
      isValid = false;
    }

    return isValid ? null : { 'sourceDestinationEqual': true };

  };

