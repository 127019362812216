import { Component, OnInit, Input, Output } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AdminPanelService, CompanyDomain } from '../admin-panel.service';
import { UserAccountService } from '../user-account.service';
import { Constants } from '../util/constants';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { Subscription, BehaviorSubject } from 'rxjs';
import { AddEmployeeComponent } from '../add-employee/add-employee.component';
import { EventEmitter } from 'protractor';

@Component({
  selector: 'app-lookup-employee-by-email',
  templateUrl: './lookup-employee-by-email.component.html',
  styleUrls: ['./lookup-employee-by-email.component.scss']
})
export class LookupEmployeeByEmailComponent implements OnInit {

  @Input() mode: string;
  emailForm: FormGroup;
  domainOptions = [];
  selectedOption = [];
  checkEmployeeDetailResponseSubscription: Subscription;
  private lookupResponse = new BehaviorSubject<any>(null);
  lookupResponseObservable$ = this.lookupResponse.asObservable();
  constructor(private adminPanelService: AdminPanelService,
    private userAccountService: UserAccountService,
    private fb: FormBuilder,
  ) { }

  ngOnInit() {
    let apiDomainList: Array<CompanyDomain> = this.adminPanelService.getDomains();
    this.selectedOption.push({ value: apiDomainList[0].domain });
    for (let domainItem of apiDomainList) {
      this.domainOptions.push({ value: domainItem.domain, id: '' + domainItem.domainId });
    }

    this.emailForm = this.fb.group({
      emailUserName: ['', Validators.compose([Validators.required])],
      domain: ['', Validators.compose([Validators.required])],
      email: ['', Validators.compose([Validators.required, Validators.pattern(Constants.RGEX_EMAIL)])],
    });

    // this.checkEmployeeDetailResponseSubscription = this.adminPanelService.employeeCheckDetailResponseObservable$.subscribe(response =>{
    //   if(response){
    //     this.lookupResponse.next(response);
    //   }
    // });

  }

  emailUserNameChange(userName) {
    let domain = this.emailForm.controls['domain'].value;
    this.emailForm.controls['email'].setValue(userName.trim() + '@' + domain);
  }
  emailDomainChange(domain) {
    let userName = this.emailForm.controls['emailUserName'].value;
    this.emailForm.controls['email'].setValue(userName.trim() + '@' + domain.value);
  }
  checkEmployeeDetail() {
    if (this.emailForm.valid) {
      this.adminPanelService.processCheckEmployeeDetail(this.emailForm.controls['email'].value,
        this.userAccountService.getUserCompanyId(), 'addAdmin');
    } else {
      this.emailForm.controls['emailUserName'].markAsTouched();
      this.emailForm.controls['email'].markAsTouched();
      this.emailForm.controls['emailUserName'].updateValueAndValidity();
      this.emailForm.controls['email'].updateValueAndValidity();
    }

  }

  onModelCancel() {
    // this.bsModalRef.hide();
  }

}
