import { BookingCardDetails } from './booking-card-details';
import { CardInfo } from './card-info';

export class PaymentInstrumentDetails {

  card: CardInfo;
  appliedGallopCash: number;
  paymentMethod: string;
  availableGallopCash: number;
  public constructor(fields?: any) {

    if (fields) {
      Object.assign(this, fields);
    }
  }
}
