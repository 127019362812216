import { Component, OnInit, Input, EventEmitter, Output, ViewChild, ElementRef } from '@angular/core';
import { Subscription } from 'rxjs';
import { SearchService } from '../search.service';
import { environment } from 'src/environments/environment';
import { UserAccountService } from '../user-account.service';

@Component({
  selector: 'app-switch-component',
  templateUrl: './switch-component.component.html',
  styleUrls: ['./switch-component.component.scss']
})
export class SwitchComponentComponent implements OnInit {

  @Input() leftOptionText: string;
  @Input() rightOptionText: string;
  @Input() leftOptionValue: string;
  @Input() rightOptionValue: string;
  @Input() lastSelectedOption: string;
  @Input() selectedOption: string;
  @Input() switchGroupName: string;
  @Input() toggleSwitchClassName: string = 'class-toggle-switch';
  @Input() toggleSwitchLabelClassName: string = '';
  @Output() tripTypeChange = new EventEmitter();

  @ViewChild('leftOption') leftOption: ElementRef;
  @ViewChild('rightOption') rightOption: ElementRef;

  constructor(private searchService: SearchService,
    private userAccountInfoService: UserAccountService) {

  }

  ngOnInit() {
    if (this.lastSelectedOption) {
      this.setSelection(this.lastSelectedOption);
    }
  }

  setSelection(selectedOption: string) {

    if (selectedOption !== this.lastSelectedOption) {
      if (this.tripTypeChange) {
        this.tripTypeChange.emit(selectedOption);
      }
    }
    this.selectedOption = selectedOption;
    this.lastSelectedOption = selectedOption;
  }
}
