import { TravelersInfo } from './travelers-info';
import { PhoneNumberDTO } from './phonenumber-dto';

export class TravelerDetails {
  email: string;
  phoneNumber: string;
  emergencyContact = {
    name: '',
    relationship: '',
    contactNumber: new PhoneNumberDTO(),
  };
  travellers: TravelersInfo[];

  public constructor(fields?: any) {

    if (fields) {
      Object.assign(this, fields);
    }
  }
}
