import { Travellers } from "./travellers";

export class HotelSearchRequest {
  public checkInDate: string;
  public checkOutDate: string;
  public destination: string;
  public currentLocation: string;
  public travellerRooms: Array<any>;
  public totalTravellers: string;
  public travellerEmail: string;
  public hotelChain: string;
  public constructor(fields?: any) {

    if (fields) {
      Object.assign(this, fields);
    }
  }
}
