<div #modal class=" show" id="">
  <div class="modal-dialog ">
    <div class="modal-content">
      <div class="modal-header">
        <span class="icon-search-from modal-search-from"></span>

        <button type="button" class="close pull-right cross-button" aria-label="Close" (click)="hide()">
          <span class="icon-cross modal-cross"></span>
        </button>
      </div>
      <div class="modal-body">
        <h4 class="modal-title pull-left title-msg">Leave Site ?</h4>
        <div class="email-text-content">Your request is being processed</div>

        <div class="modal-button-wrapper">
          <button class="btn btn-default modal-button" (click)="hide()">Return</button>
        </div>

      </div>
    </div>
  </div>
</div>