import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject, fromEvent } from 'rxjs';
import { debounceTime, map, distinctUntilChanged, startWith, tap } from 'rxjs/operators';
import { AddressDTO } from './entity/address-dto';
import { environment } from 'src/environments/environment';
import { Constants } from './util/constants';
import { GallopHttpClient } from './shared/gallop-httpclient.service';

@Injectable({
  providedIn: 'root'
})
export class DeviceDetailsService {

  resize$: Observable<number>;
  isMobile$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  isMobile2$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  isMobile1$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public currScreenWidth$: BehaviorSubject<number> = new BehaviorSubject(1024);
  public isMobile2Width = false;
  userIPLoation$ = new BehaviorSubject<AddressDTO>(null);
  userAddressFromIP: AddressDTO;

  constructor(private http: GallopHttpClient) {

    // works on window resize event can be made to work on load no need of observable in that case
    this.resize$ = fromEvent(window, 'resize')
      .pipe(
        debounceTime(200),
        map(() => window.innerWidth),
        distinctUntilChanged(),
        startWith(window.innerWidth),
        tap(width => {
          if (width <= 768) {
            this.isMobile$.next(true);
          } else {
            this.isMobile$.next(false);
          }
          if (width <= 767) {
            this.isMobile1$.next(true);
          } else {
            this.isMobile1$.next(false);
          }
          if (width <= 1024) {
            this.isMobile2$.next(true);
            this.isMobile2Width = true;
          } else {
            this.isMobile2$.next(false);
            this.isMobile2Width = false;
          }
          this.currScreenWidth$.next(width);
        }),
      );
    this.resize$.subscribe();
    setTimeout(() => {
      this.subscribeDeviceLocation();
    }
      , 5000);
  }

  isMobile(): Observable<boolean> {
    return this.isMobile$;
  }
  isMobile2(): Observable<boolean> {
    return this.isMobile2$;
  }
  isMobile1(): Observable<boolean> {
    return this.isMobile1$;
  }
  private subscribeDeviceLocation() {
    return this.http.get(environment.apiLocationFromIP).subscribe(detailsRsp => {
      let details: any = detailsRsp;
      if (details && details.status && details.status === 'success' && details.data) {
        this.userAddressFromIP = details.data;
        this.userIPLoation$.next(this.userAddressFromIP);
      }
    });
  }
}
