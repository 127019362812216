import { Component, OnInit } from '@angular/core';
import { CarBookingService } from '../car-booking.service';
import { environment } from 'src/environments/environment';
import { Subscription } from 'rxjs';
import { UserAccountService } from '../user-account.service';

@Component({
  selector: 'app-car-modal',
  templateUrl: './car-modal.component.html',
  styleUrls: ['./car-modal.component.scss']
})
export class CarModalComponent implements OnInit {
  carSearchDetails: any = {};
  searchRequestSubscription: Subscription;
  njoySpecificBuild: boolean;
  constructor(private carBookingService: CarBookingService,
    private userAccountService: UserAccountService,) { }

  ngOnInit() {
    this.njoySpecificBuild = this.userAccountService.isItNjoyBuild();
    this.searchRequestSubscription = this.carBookingService.carRequest$.subscribe(request => {
      if (request) {
        this.carSearchDetails = request;
      }
    });

  }
}
