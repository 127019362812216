import { IntervalType } from '../enum/interval.type';

export class JourneyDate {


  public constructor(fields?) {
    if (fields instanceof Object) { Object.assign(this, fields) }
  }

  public intervalStart: string;
  public intervalEnd: string;
  public intervalType: string;
}
