<div *ngIf="!njoySpecificBuild">
    <div #searchModal class="modal-body">
        <div class="modal-loader">
            <div class="modal-loader-top">
                <div class="modal-waiting-img">
                    <img class="image rotating" src="assets/images/no-flight-found.png" />
                    <img class="modal-logo" style="margin-top: 12.55px; margin-bottom: 18.5px;"
                        src="assets/images/logo.png" />
                </div>
                <div class="modal-loader-top-text">
                    <p>{{'searchModal.Searchingforflightsthatbestmatchyourpreferences' | translate}}</p>
                </div>
            </div>
            <div class="modal-loader-bottom">
                <div class="modal-loader-bottom-img">
                    <img style="width: 228px; height: 78px;" src="assets/images/Bitmap.png" />
                </div>

                <div class="arrow-icon-vertical-container">
                    <img src="assets/images/Line.png" />
                </div>
                <div class="modal-loader-bottom-text">
                    <p>{{'searchModal.Theseiconswill' | translate}}<br />{{'searchModal.highlightyourpreferences' |
                        translate}}</p>
                </div>
            </div>
        </div>
    </div>
</div>


<div *ngIf="njoySpecificBuild">
    <div #searchModal class="modal-body1">
        <div class="modal-loader1">
            <div class="modal-loader-top1">
                <div class="modal-loader-bottom1" style="margin-top:150px !important;margin-bottom:0px !important;">
                    <div class="filter-row" style="width: 215px;
                margin: 0 auto;">
                        <div class="col-auto">
                            <img class="inlineblock_m" src="assets/images/flight-white-icon.png" />
                        </div>
                        <div class="col-auto">
                            <span class="search"> {{'searchModal.SearchingForFlights' | translate}}</span>
                        </div>
                    </div>
                    <div class="result">
                        <div class="result1" style="margin-top:10px !important;">{{this.source}} <span
                                class="icon-arrow"
                                style="margin-left:5px !important;margin-right:5px !important;"></span>{{this.destination}}
                        </div>
                        <div class="result1">{{getDisplayDate(this.departureDate, 'EEE MMM d')}} -<span
                                *ngIf="this.trip == 'ROUNDTRIP'">{{getDisplayDate(this.arrivalDate, 'EEE MMM d')}}
                            </span>
                            <span class="icon-customer"
                                style="margin-left:8px !important;margin-right:8px !important;"></span>{{this.passenger}}
                        </div>
                    </div>
                </div>
                <div class="modal-loader-bottom-text">
                </div>
                <div class="col-auto" style="display:inline-block;margin:0 auto;">
                    <loader-dots [spinnerStyle]="true"></loader-dots>
                </div>
            </div>
        </div>
    </div>
</div>