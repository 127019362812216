import { Component, OnInit } from '@angular/core';
import { DeviceDetailsService } from '../device-details.service';
import { Subscription } from 'rxjs';
import { UserAccountService } from '../user-account.service';
import { UserAccountInfo } from '../entity/user-account-info';
@Component({
  selector: 'app-search-request',
  templateUrl: './search-request.component.html',
  styleUrls: ['./search-request.component.scss']
})
export class SearchRequestComponent implements OnInit {
  isMobile: boolean;
  userName = '';
  deviceSubscription: Subscription;
  private userAccountSubscription: Subscription;
  constructor(private deviceDetailsService: DeviceDetailsService, private userAccountService: UserAccountService) {
  }

  ngOnInit() {
    this.userAccountSubscription = this.userAccountService.userAccountInfoObjObserver$.subscribe((userAccountInfo: UserAccountInfo) => {
      this.userName = this.userAccountService.getUserName();
    });
    this.deviceSubscription = this.deviceDetailsService.isMobile().subscribe(isMobile => {

      this.isMobile = isMobile;
    });
  }

  isLoggedIn() {
    return this.userAccountService.isLoggedIn();
  }
}
