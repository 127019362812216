import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { BsModalService, BsModalRef, ModalDirective } from 'ngx-bootstrap/modal';
import { environment } from 'src/environments/environment';
import { SearchService } from '../search.service';
import { FlightSearchQueryParam } from '../entity/flight-search-query-param';
import { UserAccountService } from '../user-account.service';
import { DateUtils } from '../util/date-utils';

@Component({
  selector: 'search-modal-basic',
  templateUrl: './search-modal.component.html',
  styleUrls: ['./search-modal.component.scss']
})
export class searchModalBasic implements OnInit {
  @Input() title: string = '';
  njoySpecificBuild: boolean;
  previousSearch: FlightSearchQueryParam;
  source: any;
  destination: any;
  departureDate: any;
  passenger: any;
  trip: any;
  arrivalDate: any;
  @ViewChild('searchModal') modal: ModalDirective;
  constructor(
    private searchService: SearchService,
    private modalService: BsModalService,
    private userAccountService: UserAccountService,
    public bsModalRef: BsModalRef) { }
  public flightSearchQueryParam: FlightSearchQueryParam;
  public ngOnInit(): void {
    this.njoySpecificBuild = this.userAccountService.isItNjoyBuild();


    if (this.title) {
      this.previousSearch = this.searchService.flightSearchQueryParam;
      if (this.previousSearch) {
        this.source = this.previousSearch.source;
        this.destination = this.previousSearch.destination;
        this.departureDate = this.previousSearch.departureDate;
        this.passenger = this.previousSearch.passengers;
        this.trip = this.previousSearch.trip;
        this.arrivalDate = this.previousSearch.arrivalDate;
      }
    }
  }
  getDisplayDate(date, format: string): string {
    if(date){
    return DateUtils.getFormattedDateWithoutTimeZoneFromFormat(date, format);
    }
  }
}
