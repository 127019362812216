
import { TravelerDetails } from './traveler-details';
import { PaymentInstrumentDetails } from './payment-instrument-details';
import { FlightResult } from './flight-result';
import { FlightSearchRequest } from './flight-search-request';
import { HotelDetailResult } from './hotel-detail-result';
import { HotelSearchRequest } from './hotel-search-request';

export class HotelBookingRequest {

  hotelSearchQuery: HotelSearchRequest;
  travellerDetails: TravelerDetails;
  paymentDetails: PaymentInstrumentDetails;
  hotelsToBook: HotelDetailResult[];

  eventIdAndOptionMap: Map<string, string>;
  tripId: string;
  ticketId: String;
  expensifyId: string;
  displayPrice:number;
  displayCurrency:string;
  addToExpensify: boolean;
  lastTransactionId: string;
  expenseProvider: string;
  public constructor(fields?: any) {

    if (fields) {
      Object.assign(this, fields);
    }
  }
}
