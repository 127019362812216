import { Injectable } from '@angular/core';
@Injectable({
  providedIn: 'root'
})
export class GallopLocalStorageService {

  private APP_INSTANCE_KEY: string;

  private KEY_GROUP_ARRAY: string = 'KEY_GROUP_ARRAY';
  constructor() {

  }

  public initAppSessionKey() {
    this.APP_INSTANCE_KEY = this.generateUUID();
  }

  public getSessionKey() {
    return this.APP_INSTANCE_KEY;
  }
  public setItem(key: string, value: string) {

    // localStorage.setItem(this.APP_INSTANCE_KEY + '_'+ key,value);
    sessionStorage.setItem(this.APP_INSTANCE_KEY + '_' + key, value);
    // this.addKeyToSessionStorageList(key);
  }

  public getItem(key: string): string {
    // return localStorage.getItem(this.APP_INSTANCE_KEY + '_'+ key);
    return sessionStorage.getItem(this.APP_INSTANCE_KEY + '_' + key);
  }

  public removeItem(key: string) {
    // localStorage.removeItem(this.APP_INSTANCE_KEY + '_'+ key);
    sessionStorage.removeItem(this.APP_INSTANCE_KEY + '_' + key);
  }
  //depreciated
  public clear() {
    // if(localStorage.getItem(this.APP_INSTANCE_KEY+'_'+this.KEY_GROUP_ARRAY)!=null){
    //   let sessionStorageList : Array<string> = JSON.parse(localStorage.getItem(this.APP_INSTANCE_KEY+'_'+this.KEY_GROUP_ARRAY));
    //   for(let key  of sessionStorageList){
    //     localStorage.removeItem(this.APP_INSTANCE_KEY + '_'+ key);
    //   }
    // }
  }

  //depricated
  private addKeyToSessionStorageList(key: string) {
    let sessionStorageList: Array<string> = null;
    if (localStorage.getItem(this.APP_INSTANCE_KEY + '_' + this.KEY_GROUP_ARRAY) != null) {
      sessionStorageList = JSON.parse(localStorage.getItem(this.APP_INSTANCE_KEY + '_' + this.KEY_GROUP_ARRAY));
    } else {
      sessionStorageList = new Array<string>();
    }
    if (!sessionStorageList.find(x => x === key)) {
      sessionStorageList.push(key);
    }
    localStorage.setItem(this.APP_INSTANCE_KEY + '_' + this.KEY_GROUP_ARRAY, JSON.stringify(sessionStorageList));
  }

  private generateUUID(): string {
    var text = "";
    var possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

    for (var i = 0; i < 5; i++)
      text += possible.charAt(Math.floor(Math.random() * possible.length));

    return text;
  }
}
