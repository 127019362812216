import { Injectable } from '@angular/core';
import { Observable, observable } from 'rxjs';

declare var google: any;

@Injectable({
  providedIn: 'root'
})
export class GooglePlacesService {
  autocomplete: any;

  constructor() { }

  search(query): Observable<any> {
    let a = google.maps.places.AutocompleteService;
    return this.autocomplete;
  }
}
