import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { ModalDirective, BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { CommonUtils } from 'src/app/util/common-utils';
import { UserAccountService } from 'src/app/user-account.service';
import { UserAccountInfo } from 'src/app/entity/user-account-info';
import { deserialize } from 'src/app/util/ta-json/src/methods/deserialize';
import { PassportDTO } from 'src/app/entity/passport-dto';
import { DatePipe } from '@angular/common';
import { UserPreferences } from 'src/app/entity/user-prefs';
import { AddressDTO } from 'src/app/entity/address-dto';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { AdminPanelService } from 'src/app/admin-panel.service';

@Component({
  selector: 'app-delete-card-model',
  templateUrl: './delete-card-model.component.html',
  styleUrls: ['./delete-card-model.component.scss']
})
export class DeleteCardModelComponent implements OnInit {

  @ViewChild('modal', { static: true }) modal: ModalDirective;
  @Input() title: string;
  @Input() message: string;
  @Input() index: any;
  @Input() yesButtonSubText: string;
  @Input() noButtonSubText: string;
  @Input() disableCancel: boolean;
  @Input() color: string = 'red';
  @Input() buttonColor: string = 'red';
  @Input() dashboard = false;
  @Input() profile = false;
  @Input() login = false;
  @Input() deleteCard = false;
  @Input() passengerDetails: any;
  @Input() userInfoDTO: any;
  @Input() flightChart=false;
  @Input() others=false;

  private isCancellable = true;
  constructor(
    private modalService: BsModalService,
    public translateService: TranslateService,
    public userAccountInfoService: UserAccountService,
    private adminPanelService: AdminPanelService,
    private toastr: ToastrService,
    public bsModalRef: BsModalRef) { }


  acceptClickSubject = new Subject();
  public onClose: Subject<boolean>;


  public ngOnInit(): void {
    this.onClose = new Subject();
  }

  public onConfirm(): void {
    this.onClose.next(true);
    // this.acceptClickSubject.next(true);
    if (this.deleteCard) {
      this.userAccountInfoService.deletingCard = true;
    }
    if (this.profile) {
      CommonUtils.saveProfileandPreference(this.userInfoDTO, this.passengerDetails, this.userAccountInfoService, this.toastr,this.translateService,this.adminPanelService,this.others);
     
      this.bsModalRef.hide();

    }

    // 
  }

  public onCancel(): void {
    this.onClose.next(false);
    if (this.profile) {
      this.userAccountInfoService.doNotSaveProfile = true;
      if(!this.userAccountInfoService.promptUserTosaveProfile){
        this.userAccountInfoService.promptUserTosaveProfile = true;
      }
    }
    // this.acceptClickSubject.next(true);
    this.bsModalRef.hide();
  }
  isAmnetRelease() {
    return environment.amnetSpecificRelease;
  }
}
