<div *ngIf="!njoySpecificBuild">
  <div #searchModal class="modal-body">
    <div class="modal-loader">
      <div class="modal-loader-top">
        <div class="modal-waiting-img">
          <img class="image rotating" src="assets/images/no-flight-found.png" />
          <img class="modal-logo" style="margin-top: 12.55px; margin-bottom: 18.5px;" src="assets/images/logo.png" />
        </div>
        <div class="modal-loader-top-text">
          <p>{{'bookingHistory.Retrievingresultsofyourbookinghistory' | translate}}</p>
        </div>
      </div>
      <div class="modal-loader-bottom">
        <div class="modal-loader-bottom-img">
        </div>


      </div>
    </div>
  </div>
</div>


<div *ngIf="njoySpecificBuild">
  <div #searchModal class="modal-body1">
    <div class="modal-loader1">
      <div class="modal-loader-top1">
        <div class="modal-loader-bottom1" style="margin-top:150px !important;margin-bottom:0px !important;">
          <div class="filter-row" style="width: 215px;
          margin: 0 auto;">
            <div class="col-auto">
              <img class="inlineblock_m" src="assets/images/flight-white-icon.png" />
            </div>
            <div class="col-auto">
              <span class="search">{{'bookingHistory.LoadingTrips' | translate }}</span>
            </div>
            <div class="col-auto" style="display:inline-block;margin:0 auto;">
              <loader-dots style="position: relative;top:-10px;" [spinnerStyle]="true"></loader-dots>
            </div>
          </div>

          <div class="modal-loader-bottom-text">

          </div>
        </div>
      </div>
    </div>
  </div>