
import { TravelerDetails } from './traveler-details';
import { PaymentInstrumentDetails } from './payment-instrument-details';
import { FlightResult } from './flight-result';
import { FlightSearchRequest } from './flight-search-request';
import { HotelDetailResult } from './hotel-detail-result';
import { HotelSearchRequest } from './hotel-search-request';
import { SeatSelect } from './seatSelected';

export class BookingApprovalRequest {

  hotelSearchQuery: HotelSearchRequest;
  travellerDetails: TravelerDetails;
  paymentDetails: PaymentInstrumentDetails;
  hotelsToBook: HotelDetailResult[];
  carsToBook: any;
  expenseProvider: string;
  flightsToBook: { flights: FlightResult[] };
  flightSearchQuery: FlightSearchRequest;
  carSearchQuery: any;
  bookingType: string;
  rebookingInfo:any;
  noteToAdmin:string;
  eventIdAndOptionMap: Map<string, string>;
  tripId: string;
  selectedSeat: SeatSelect[];
  ticketId: String;
  displayPrice:number;
  displayCurrency:string;
  expensifyId: string;
  addToExpensify: boolean;
  projectTagId: string;
  lastTransactionId: string;
  previousBookingCodes: Array<any> = [];
  bookingStatusId: any = null;
  public constructor(fields?: any) {

    if (fields) {
      Object.assign(this, fields);
    }
  }
}
