import { AddressDTO } from './address-dto';
import { PassportDTO } from './passport-dto';
import { PhoneNumberDTO } from './phonenumber-dto';
import { UserEntity } from './user-entity';
import { FrequentFlyerInfo } from './frequent-flyer-info';
import { EmployeeInfo } from './employee-info';
import { CompanySettings } from '../admin-panel.service';
export class UserInfo {

  public is_corporate_user: boolean;
  public userId: number;
  public exists: boolean;
  public email: string;
  public expensifyId: string;
  public addToExpensify: boolean;
  public userEntityData: UserEntity;
  public profileImageUrl: string;
  public known_traveller_number: string;
  public alt_emails: Array<string>;
  public phoneNumber: PhoneNumberDTO;
  public passengerType: string;
  public countryCode: string;
  public is_active: boolean;
  public currency: string;
  public seatPref: string;

  public title: string;

  public gender: string;

  public zipcode: string;

  public firstname: string;

  public lastname: string;

  public middlename: string;

  public firstName: string;

  public lastName: string;

  public middleName: string;

  public dob: string;

  public userAge: number;

  public home: AddressDTO;

  public fullName: string;

  public work: AddressDTO;

  public passportDTO: PassportDTO;

  public username: string;
  public nickname: string;
  public suffix: string;
  public travelerType: string;
  public nationality: string;
  public companyId: number;
  public companyName: string;
  public emergencyContact = {
    name: '',
    relationship: '',
    contactNumber: new PhoneNumberDTO(),
  };
  public employeeInfo: EmployeeInfo;
  public ffnMapping: Array<FrequentFlyerInfo>;
  public loyalityCards: Array<any>;
  public carLoyaltyNumbers: Array<any>;

  public constructor(fields?: any) {

    if (fields) {
      Object.assign(this, fields);
    }
  }
}
