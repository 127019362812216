export class NavigationUtil {
  public static NAVIGATION_MENU_BOOK = 'book';
  public static NAVIGATION_MENU_PROFILE = 'profile';
  public static NAVIGATION_MENU_TRIPS = 'trip';
  public static NAVIGATION_MENU_SUPPORT = 'support';
  public static NAVIGATION_MENU_RESULTS = 'results';
  public static NAVIGATION_MENU_APPROVAL = 'approval';
  public static NAVIGATION_UTIL_STORAGE_KEY = 'NAVIGATION_UTIL_CURRENT';
  public static currentMenu = 'book';

  public static getCurrentNavigationMenu() {
    let currentItem = localStorage.getItem(NavigationUtil.NAVIGATION_UTIL_STORAGE_KEY);
    if (currentItem && currentItem.trim().length > 0) {
      return currentItem;
    }
    return NavigationUtil.currentMenu;
  }
  static setCurrentNavigationMenu(currentMenuInput) {
    NavigationUtil.currentMenu = currentMenuInput;
    localStorage.setItem(NavigationUtil.NAVIGATION_UTIL_STORAGE_KEY, currentMenuInput);
  }
}
