
import { TravelerDetails } from './traveler-details';
import { PaymentInstrumentDetails } from './payment-instrument-details';
import { FlightResult } from './flight-result';
import { FlightSearchRequest } from './flight-search-request';
import { SeatSelect } from './seatSelected';

export class BookingRequest {

  travellerDetails: TravelerDetails;
  paymentDetails: PaymentInstrumentDetails;
  flightsToBook: { flights: FlightResult[] };
  searchQuery: FlightSearchRequest;
  eventIdAndOptionMap: Map<string, string>;
  tripId: string;
  ticketId: String;
  rebookingInfo:any;
  previousBookingCodes: Array<any> = [];
  expensifyId: string;
  addToExpensify: boolean;
  displayPrice:number;
  displayCurrency:string;
  expenseProvider: string;
  projectTagId: string;
  lastTransactionId: string;
  selectedSeat: SeatSelect[];
  public constructor(fields?: any) {

    if (fields) {
      Object.assign(this, fields);
    }
  }
}
