import { Component, OnInit, ViewChild } from '@angular/core';
import { BsModalService, BsModalRef, ModalDirective } from 'ngx-bootstrap/modal';
import { environment } from 'src/environments/environment';
import { UserAccountService } from '../user-account.service';

@Component({
  selector: 'app-hotel-history-modal',
  templateUrl: './hotel-history-modal.component.html',
  styleUrls: ['./hotel-history-modal.component.scss']
})
export class HotelHistoryModalComponent implements OnInit {
  njoySpecificBuild: boolean;
  @ViewChild('searchModal') modal: ModalDirective;
  constructor(
    private modalService: BsModalService,
    private userAccountService: UserAccountService,
    public bsModalRef: BsModalRef) { }
  ngOnInit() {
    this.njoySpecificBuild = this.userAccountService.isItNjoyBuild();
  }

}
