import { IPropertyConverter } from "../converters";

export class PropertyDefinition {
    public type: Function;
    public elementType: Function;
    public readonly: boolean = false;
    public writeonly: boolean = false;
    public converter: IPropertyConverter;
    public serializedName: string = Symbol('serializedName') as unknown as string;

    constructor() { }
}
