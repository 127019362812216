import { BookedOption } from "../util/bookingdata";

export class UserInfoBasic {
  public userId: string;
  public departmentId: string;
  public userName: string;

}
export class CompanyReportResponse {
  public bookingList: Array<BookedOption> = [];
  public pendingApprovals: Array<BookedOption> = [];
  public approvalHistory: Array<BookedOption> = [];
  public users: Map<string, UserInfoBasic>;
  public approvers: Map<string, Array<any>>;
  public airlineNames: Map<string, string>;
  public airports: Map<string, any>;
  public columns: Array<string>;
  public totalAmount: number;
  public type: string;
  public constructor(fields?: any) {

    if (fields) {
      Object.assign(this, fields);
    }
  }
}
