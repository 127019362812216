
import { FlightResult } from './flight-result';
import { ScoringLabel } from './scoring-label';

export class FlightSearchResponse {

  public airlineNames: any = {};
  public airports: any = {};
  public cabinClassNames: any = {};
  public flightsLists: { flights: FlightResult[] }[];
  public flightPolicy: {};
  public scoringLabels: { labels: ScoringLabel[] };
  public summary : any;
  public policySet: boolean = false;
  public constructor(fields?: any) {

    if (fields) {
      Object.assign(this, fields);
    }
  }
}
