import { DateConverter } from "./date-converter";
import { JsonValue } from "../types";

export interface IPropertyConverter {
    serialize(property: any): JsonValue;
    deserialize(value: JsonValue): any;
}

export const propertyConverters: Map<Function, IPropertyConverter> = new Map<Function, IPropertyConverter>();

propertyConverters.set(Date, new DateConverter());
