import { Injectable } from '@angular/core';
import { environment } from '../environments/environment';
import { Observable } from 'rxjs';
import { GallopHttpClient } from './shared/gallop-httpclient.service';

@Injectable()
export class GeoLocationService {

  constructor(private http: GallopHttpClient) { }

  getLocationByZipCode(zipCode: string): Observable<any> {
    return this.http.get(environment.googleMapsGeoCodeZipCodeUrl + zipCode + '&key=' + environment.googleMapsAPIKey);
  }

  getLocationByLatLng(lat: string, lng: string): Observable<any> {
    return this.http.get(environment.googleMapsGeoCodeLocationeUrl + lat + ',' + lng + '&key=' + environment.googleMapsAPIKey);
  }

}
