<div class="modal-header">
  <h5 class="modal-title" id="myModalLabel">
    Add an employee
  </h5>
  <button type="button" class="close" data-dismiss="modal" (click)="onModelCancel()">
    <i class="material-icons" style="color:#fff;">close</i>
  </button>
</div>
<div class="modal-body" style=" text-align: left !important;">
  <form [formGroup]="emailForm">
    <span class="heading">Add an employee</span>
    <div class="row" style="margin-top:12px;">
      <div class="col-12 col-md-5">
        <label class="name">Email</label>
        <input type="text" class="formControl" formControlName="emailUserName" placeholder=""
          (input)="emailUserNameChange($event.target.value)" />
        <div
          *ngIf="emailForm.controls['email'].hasError('pattern') && (emailForm.controls['email'].touched || emailForm.controls['email'].dirty)"
          class="error">{{'login.pleaseenteravalidemail' | translate}}
        </div>
        <div
          *ngIf="emailForm.controls['emailUserName'].hasError('required') && (emailForm.controls['emailUserName'].touched || emailForm.controls['emailUserName'].dirty)"
          class="error">{{'login.thisfieldisrequired' | translate}}
        </div>
        <div
          *ngIf="emailForm.controls['domain'].hasError('required') && (emailForm.controls['emailUserName'].touched || emailForm.controls['emailUserName'].dirty)"
          class="error">{{'Please select a domain' | translate}}
        </div>
      </div>
      <div class="col-1 d-none d-md-block"
        style="margin-top:25px !important; margin-left:15px !important;text-align:center !important;">
        <span class="symbol">@</span>
      </div>
      <div class="col-xl-12 d-block d-md-none" style="">
        <span class="symbol">@</span>
      </div>
      <div class="col-12 col-md-5">
        <div class="formControl1">
          <ng-select #domain dropdownPosition="bottom" [searchable]="false" [clearable]="false" formControlName="domain"
            [items]="domainOptions" bindLabel="value" bindValue="value" [(ngModel)]="this.selectedOption[0].value"
            (change)="emailDomainChange($event)">
            <span class="selectBox-remove"><span class="material-icons">clear</span></span>
            <span class="fa fa-chevron-down icon"></span>
          </ng-select>
        </div>
      </div>
    </div>
    <button class="btn btn-secondary" style="margin-top:15px !important;" (click)="checkEmployeeDetail()"><span
        class="add">LOOKUP OR ADD</span></button>
    <div class="modal-form-button">

    </div>
  </form>
</div>