export class PassportDetails {

  passportNumber: string;
  passportExpiryDate: string;
  passportCountryCode: string;

  public constructor(fields?: any) {

    if (fields) {
      Object.assign(this, fields);
    }
  }
}
