export class Travellers {

  adults: number = 1;
  infants: number = 0;
  children: number = 0;

  constructor(fields?) {
    if (fields) {
      Object.assign(this, fields);
    }

  }

}
