<div class="overlay"></div>
<div class="pageBody">
    <email-header></email-header>
    <!-- <header>
        <div class="container">
            <div class="row">
                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                    <div class="header-inner">
                        <div class="header-left">
                            <div class="logo">
                                <a href="javascript:void(0)">
                                    <span class="logo-icon"></span>
                                </a>
                            </div>
                        </div>
                        <div class="header-right">
                            <div class="user-profile">
                                <div class="user-name-img">
                                    <span class="user-img inlineblock_m"><img *ngIf="getProfileImage()===null" id="profile-image-div" src="assets/images/profile-head.jpg"
                                            alt="" />
                                        <img *ngIf="getProfileImage()!==null" id="profile-image-div" src="{{getProfileImage()}}"
                                            alt="" /></span>
                                            
                                    <span class="user-name inlineblock_m" id="profile-name-div">{{userEmailId}}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </header> -->
    <section class="content">
        <div class="container">
            <div class="row">
                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                    <div class="page-content">
                        <button style="float:right; margin-bottom:10px;margin-top:25px;" class="button button-primary"
                            (click)="goToFlightBookingUI();">Book Flights</button>
                        <div class="card">

                            <div class="card-header">
                                <h3>Account Details</h3>
                                <p class="themeColor2">GallopCash Balance: <span>{{getGallopCash() | currency}}</span>
                                </p>
                            </div>
                            <form [formGroup]="contactForm" (ngSubmit)="onSubmit()">
                                <div class="card-body">
                                    <div formGroupName="usrAccInputs" class="row">
                                        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                            <label class="input-label">Name</label>
                                            <div class="input-box">
                                                <div class="row">
                                                    <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                                                        <input formControlName="firstName" class="input-textfield"
                                                            id="firstName" name="firstName" type="text"
                                                            placeholder="First Name" />
                                                        <div *ngIf="getFormControl('firstName').invalid && (getFormControl('firstName').dirty || getFormControl('firstName').touched)"
                                                            class="error">
                                                            <span
                                                                *ngIf="getFormControl('firstName').hasError('required')">This
                                                                field is required.</span>
                                                            <span
                                                                *ngIf="getFormControl('firstName').hasError('pattern')">Please
                                                                enter only apha.</span>
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                                                        <input formControlName="middleName" class="input-textfield"
                                                            id="middleName" name="middleName" type="text"
                                                            placeholder="Middle Name(If shown on ID)" />
                                                        <div *ngIf="getFormControl('middleName').invalid && (getFormControl('middleName').dirty || getFormControl('middleName').touched)"
                                                            class="error">
                                                            <span
                                                                *ngIf="getFormControl('middleName').hasError('pattern')">Please
                                                                enter only apha.</span>
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                                                        <input formControlName="lastName" class="input-textfield"
                                                            id="lastName" name="lastName" type="text"
                                                            placeholder="Last Name" />
                                                        <div *ngIf="getFormControl('lastName').invalid && (getFormControl('lastName').dirty || getFormControl('lastName').touched)"
                                                            class="error">
                                                            <span
                                                                *ngIf="getFormControl('lastName').hasError('required')">This
                                                                field is required.</span>
                                                            <span
                                                                *ngIf="getFormControl('lastName').hasError('pattern')">Please
                                                                enter only apha.</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <!-- <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12" style="display:none;">
                                            <label class="input-label">Primary Email</label>
                                            <div class="input-box">
                                                <div class="row">
                                                    <div class="col-lg-4 col-md-12 col-sm-12 col-xs-12">
                                                        <input formControlName="email" class="input-textfield" id="primaryEmail"
                                                            name="primaryEmail" type="text" placeholder="Primary Email"
                                                            disabled />
                                                    </div>
                                                </div>
                                            </div>
                                        </div> -->
                                        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                            <label class="input-label">Secondary Email</label>
                                            <div class="input-box">
                                                <div class="row">
                                                    <div class="col-lg-12">
                                                        <div class="sec-email-container">
                                                            <div formArrayName="alt_emails" class="sec-email">
                                                                <div *ngFor="let alterEmail of alterEmailsForm.controls;let k = index; "
                                                                    class="sec-email-inner">
                                                                    <div class="sec-email-input">
                                                                        <input [formControlName]="k"
                                                                            class="input-textfield secondaryEmail "
                                                                            id="secondaryEmail{{k}}"
                                                                            name="secondaryEmail{{k}}" type="text"
                                                                            placeholder="Secondary Email" />
                                                                        <div *ngIf="alterEmail.invalid && (alterEmail.dirty || alterEmail.touched)"
                                                                            class="error">
                                                                            <span
                                                                                *ngIf="alterEmail.hasError('pattern')">Please
                                                                                enter valid email.</span>
                                                                        </div>
                                                                    </div>
                                                                    <div class="icons-container">
                                                                        <a *ngIf="k === alterEmailsForm.controls.length - 1"
                                                                            href="javascript:void(0);"
                                                                            class="action-icon add-icon input-action-link"
                                                                            (click)="addEmailBox('');">
                                                                            <i class="fa fa-plus-circle"></i>
                                                                        </a>
                                                                        <a *ngIf="k > 0 " href="javascript:void(0);"
                                                                            class="action-icon input-action-link"
                                                                            (click)="removeEmailBox(k);">
                                                                            <i class="fa fa-minus-circle"></i>
                                                                        </a>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                            <label class="input-label">Subscription Plan</label>
                                            <div class="input-box">
                                                <div class="row">
                                                    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                                        <div>{{getSubscriptionPlanName()}} </div>
                                                        <div *ngIf="!isFreeSubscription()">(Auto renewal on
                                                            {{getSubscriptionExpiryDate()| date:'MMM dd, yyyy'}})</div>
                                                        <div *ngIf="isFreeSubscription()">(Expiry on
                                                            {{getSubscriptionExpiryDate() | date:'MMM dd, yyyy'}})</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                            <label class="input-label">Payment Method</label>
                                            <div class="input-box">
                                                <div class="row">
                                                    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                                        <!--<input class="input-textfield input-readonly" id="paymentMethod" value="Amex-100" name="paymentMethod" type="text" placeholder="Payment Method" />-->
                                                        <div *ngFor="let card of getCardList(); let j=index;">
                                                            {{card.brand}}-{{card.last4}}</div>
                                                        <div> <a href="{{getAddCardURL()}}" target="_blank">Add Card</a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                            <label class="input-label">Password</label>
                                            <div class="input-box">
                                                <div class="row passwordTemplateContainer">
                                                    <div *ngIf="!isChangePasswordMode"
                                                        class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                                        <div><span
                                                                class="inlineblock_m prev-password">**************</span>
                                                            <a class="inlineblock_m" href="javascript:void(0);"
                                                                (click)="setChangePasswordMode();">change</a>
                                                        </div>
                                                    </div>
                                                    <div *ngIf="isChangePasswordMode"
                                                        class="col-lg-4 col-md-4 col-sm-6 col-xs-12">
                                                        <div class="input-box">
                                                            <input formControlName="newPassword" class="input-textfield"
                                                                id="password" name="password" type="password"
                                                                placeholder="Password" />
                                                            <div *ngIf="getFormControl('newPassword').invalid && (getFormControl('newPassword').dirty || getFormControl('newPassword').touched)"
                                                                class="error">
                                                                <span
                                                                    *ngIf="getFormControl('newPassword').hasError('required')">This
                                                                    field is required.</span>
                                                                <span
                                                                    *ngIf="getFormControl('newPassword').hasError('minlength')">Password
                                                                    must be atleast 8 characters long.
                                                                </span>
                                                                <span
                                                                    *ngIf="getFormControl('newPassword').hasError('passwordMisMatch')">Password
                                                                    doesn't match.
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div *ngIf="isChangePasswordMode"
                                                        class="col-lg-4 col-md-4 col-sm-6 col-xs-12">
                                                        <div class="input-box">
                                                            <input formControlName="confirmPassword"
                                                                class="input-textfield" id="confirmPassword"
                                                                name="confirmPassword" type="password"
                                                                placeholder="Confirm Password" />
                                                            <div *ngIf="getFormControl('confirmPassword').invalid && (getFormControl('confirmPassword').dirty || getFormControl('confirmPassword').touched)"
                                                                class="error">
                                                                <span
                                                                    *ngIf="getFormControl('confirmPassword').hasError('passwordMisMatch')">Password
                                                                    doesn't match.
                                                                </span>
                                                                <span
                                                                    *ngIf="getFormControl('confirmPassword').hasError('required')">This
                                                                    field is required.</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <a *ngIf="isChangePasswordMode"
                                                        class="input-action-link closeChangePasswordLink"
                                                        href="javascript:void(0);"
                                                        (click)="cancelChangePasswordMode();">close</a>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="button-container bottom-button-margin bookingButtonContainer">
                                    <button type="submit" class="button button-primary">Save</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</div>
<app-navigation></app-navigation>