import { GallopCashDTO } from './gallopcash-dto';
import { UserInfo } from './user-info';
import { CardListDTO } from './card-list-dto';
import { CompanySettings } from '../admin-panel.service';
export class UserAccountInfo {
  public userInfo: UserInfo;
  public gallopCash: GallopCashDTO;
  public cardList: CardListDTO;
  public seatPref: string;
  public cardsModifiedByStripe: CardListDTO;
  public companySettings: CompanySettings;
  public connectedToSlack;

}
