import { FlightResult } from '../entity/flight-result';
import { DateUtils } from './date-utils';

export class FlightUtils {


  static getFlightLegDuration(flight: FlightResult, legIndex: number): any {
    let durationInMins = this.getFlightLegDurationInMin(flight, legIndex);
    return DateUtils.getDurationAsHrsMinObj(durationInMins);
  }
  static getFlightRebookingLegDuration(flight: FlightResult, legIndex: number): any {
    let durationInMins = this.getFlightRebookingLegDurationInMin(flight, legIndex);
    return DateUtils.getDurationAsHrsMinObj(durationInMins);
  }
  static getFlightRebookingLegDurationInMin(flight, legIndex: number): any {
    if (!flight && !flight.legs[legIndex]) return 0;
    let startDate = new Date(flight.legs[legIndex].hops[0].starts);
    let endDate = new Date(flight.legs[legIndex].hops[flight.legs[legIndex].hops.length - 1].ends);

    return DateUtils.getDateDiffInMinutes(endDate, startDate);
  }
  static getFlightLegDurationInMin(flight: FlightResult, legIndex: number): any {
    if (!flight && !flight.legs[legIndex]) return 0;
    let startDate = new Date(flight.legs[legIndex].flightHops[0].starts);
    let endDate = new Date(flight.legs[legIndex].flightHops[flight.legs[legIndex].flightHops.length - 1].ends);

    return DateUtils.getDateDiffInMinutes(endDate, startDate);
  }
  static getLegWiseSourceDestination(flight: FlightResult): any {

    if (!flight) return;

    let flightLegInfoList = [];
    let legInfo = { source: '', destination: '' };

    flight.legs.forEach(leg => {
      legInfo = { source: '', destination: '' };

      legInfo.source = leg.flightHops[0].from;
      legInfo.destination = leg.flightHops[leg.flightHops.length - 1].to;

      flightLegInfoList.push(legInfo);
    });

    return flightLegInfoList;
  }

  static getLegWiseLayoverList(flight: FlightResult): any[] {

    let flightLayoverInfoList = [];

    if (flight) {

      flight.legs.forEach((leg) => {

        let numOfHops = leg.flightHops.length;

        let flightLegLayoverInfoList = [];

        if (numOfHops > 1) {

          for (let i = 0; i < numOfHops - 1; i++) {

            var layoverIn = (leg.flightHops[i].to == leg.flightHops[i + 1].from) ?
              leg.flightHops[i].to :
              leg.flightHops[i].to + "/" + leg.flightHops[i + 1].from;

            let flightLegLayoverInfo = { in: '', duration: {} };
            flightLegLayoverInfo.in = layoverIn;

            var hop1Date = new Date(leg.flightHops[i].ends);
            var hop2Date = new Date(leg.flightHops[i + 1].starts);

            var timeDiffInMin = DateUtils.getDateDiffInMinutes(hop2Date, hop1Date);

            flightLegLayoverInfo.duration = DateUtils.getDurationAsHrsMinObj(timeDiffInMin);

            flightLegLayoverInfoList.push(flightLegLayoverInfo);
          }
        }

        if (flightLegLayoverInfoList) {
          flightLayoverInfoList.push(flightLegLayoverInfoList);
        }

      })

    }

    return flightLayoverInfoList;
  }
}
