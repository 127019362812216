import { JourneyDate } from './journey-date';
import { AddressType } from '../enum/address.type';

export class FlightLegSearch {

  public origin: string;
  public destination: string;
  public journeyDate: JourneyDate;
  public originType: AddressType = AddressType.ADDRESS;
  public destinationType: AddressType = AddressType.ADDRESS;

  public constructor(fields?: any) {

    if (fields) {
      Object.assign(this, fields);
    }
  }

}
