<div class="result-card-box">
    <div class="result-card-box-inner">
        <div class="result-details">
            <div class="result-card-box-left">
                <div class="flight-detail-container">
                    <ng-container *ngFor="let num of noOfFlightLegs">
                        <div class="flight-detail">
                            <div class="flight-name-duration">
                                <span class="flight-name-logo block"><img
                                        onerror="this.onerror = null; this.src = 'https://s3.amazonaws.com/images.biztravel.ai/template/default.gif';"
                                        [src]="getFlightIconURL(flight.legs[num].flightHops[0].carrier)" /></span>
                                <span
                                    class="flight-duration block">{{this.airlines[flight.legs[num].flightHops[0].carrier]}}</span>
                                <span class="flight-duration block" style="margin-top:5px">
                                    {{flight.legs[num].flightHops[0].flightNumber}}
                                </span>
                                <span class="flight-duration block">{{getFlightDuration(num).hrs + 'h ' +
                                    getFlightDuration(num).mins +
                                    'm'}}</span>
                            </div>
                            <div class="flight-timings-div">
                                <div class="flight-timings">
                                    <div class="flight-left">
                                        <span
                                            class="block flight-time">{{getDisplayDate(flight.legs[num].flightHops[0].starts)
                                            | date:'h:mm a'}}</span>
                                        <span class="block flight-airport">{{getFlightLegSource(num)}}</span>
                                        <span class="city">{{this.airports[getFlightLegSource(num)].name}}</span>
                                        <span
                                            class="block flight-time">{{getDisplayDate(flight.legs[num].flightHops[0].starts)
                                            | date:'MMM d'}}</span>
                                    </div>
                                    <div class="flight-right">
                                        <span
                                            class="block flight-time">{{getDisplayDate(flight.legs[num].flightHops[getNoOfHops(num)-1].ends)
                                            | date:'h:mm a'}}</span>
                                        <span class="block flight-airport">{{getFlightLegDestination(num)}}</span>
                                        <span class="city">{{this.airports[getFlightLegDestination(num)].name}}</span>
                                        <span
                                            class="block flight-time">{{getDisplayDate(flight.legs[num].flightHops[getNoOfHops(num)-1].ends)
                                            | date:'MMM d'}}</span>
                                    </div>
                                    <div class="flight-timings-line">
                                        <div class="stop-box-container">
                                            <ng-container *ngFor="let stop of flightLayoverInfoList[num];let k=index">
                                                <span class="block text-center stop-box-div"
                                                    [ngStyle]="{'width' : 100/flightLayoverInfoList[num].length + '%' }">
                                                    <span [hidden]="k>1" class="stop-box"></span>
                                                </span>
                                            </ng-container>
                                        </div>
                                    </div>
                                </div>
                                <div class="stop-div">
                                    <span class="block flight-airport"
                                        *ngIf="flightLayoverInfoList[num].length==0">{{'flightSelected.nonstop' |
                                        translate}}</span>
                                    <span class="block flight-airport"
                                        *ngIf="flightLayoverInfoList[num].length > 0">{{getStopsUIText(num)}}</span>
                                </div>
                            </div>

                        </div>
                    </ng-container>
                </div>
            </div>
            <div class="result-card-box-right">
                <div class="flight-select-container">
                    <div class="flight-price-div text-center">
                        <a class="change-link" href="javascript:void(0)"
                            (click)="deSelectFlight(flight)">{{'flightSelected.change' | translate}}</a>
                        <span class="block flight-price" [ngStyle]="getNUmberOfdigitOfPrice(getCurrencySymbol(flight),getPrice())">{{getPrice() | currency : getCurrencySymbol(flight) :
                            'code': '1.0-0' }}</span>
                        <span *ngIf="!showMuticity" class="block flight-trip-type">{{'flightSelected.Roundtrip' |
                            translate}}</span>
                        <span *ngIf="showMuticity" class="block flight-trip-type">{{'flightSelected.Multicity' | translate}}</span>
                        <div class="block flight-trip-type11" style="margin-top:5px;"
                            *ngIf="this.creditDetails && this.creditDetails.creditAmount > 0">{{'flightChart.Creditapplied' | translate}}.</div>
                        <div class="block flight-trip-type11"
                            *ngIf="this.creditDetails && this.creditDetails.creditAmount > 0">{{'flightChart.GrossFare' | translate}}:{{legType
                            ==='RETURN' && getPrice1() >= 0.5 ? '+' : (getPrice1() <= -0.5 ? '-' :'')}} {{getPrice1() |
                                currency : getCurrencySymbol(flight) : 'code' : '1.0-0' }}</div>
                                <div class="block flight-trip-type11" style="margin-top:5px;"
                                    *ngIf="this.creditDetails && this.creditDetails.length > 0">{{'flightChart.Creditapplied' | translate}}.</div>
                                <div class="block flight-trip-type11"
                                    *ngIf="this.creditDetails && this.creditDetails.length > 0">{{'flightChart.GrossFare' | translate}}:{{legType
                                    ==='RETURN' && getPrice1() >= 0.5 ? '+' : (getPrice1() <= -0.5 ? '-' :'')}}
                                        {{getPrice1() | currency : getCurrencySymbol(flight) : 'code' : '1.0-0'
                                        }}</div>
                                </div>
                                <div class="info-div" (click)="showFlightDetailModal(searchModal)">
                                    <i class="fa fa-info-circle flght-selected-info-icon" aria-hidden="true"></i>
                                </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>

        <ng-template #searchModal let-modal>
            <div class="modal-container flight-modal-container">
                <div class="modal-header">
                    <h5 class="modal-title" id="myModalLabel">
                        {{'flightSelected.Departure' | translate}} {{getFlightLegSource(0)}} ->
                        {{getFlightLegDestination(flight.legs.length - 1)}}
                    </h5>
                    <button type="button" class="close" data-dismiss="modal" (click)="onCancel()">
                        <i class="material-icons">close</i>
                    </button>
                </div>
                <div class="modal-body">
                    <ng-container *ngFor="let num of noOfFlightLegs">
                        <div class="date-duration">
                            <div class="date">{{getDisplayDate(flight.legs[num].flightHops[0].starts) | date:'EE, MMM
                                d'}}</div>
                            <div class="duration">
                                <label>{{'flightSelected.TotalDuration' | translate}}
                                </label>
                                <span>{{getFlightDuration(num).hrs + 'h ' +
                                    getFlightDuration(num).mins +
                                    'm'}}</span>
                            </div>
                        </div>

                        <div class="flight-schedule">
                            <ng-container *ngFor="let flightHop of flight.legs[num].flightHops; let i=index">
                                <div class="flight-box">
                                    <div class="flight-box-left">
                                        <img onerror="this.onerror = null; this.src = 'https://s3.amazonaws.com/images.biztravel.ai/template/default.gif';"
                                            [src]="getFlightIconURL(flightHop.carrier)" />
                                    </div>

                                    <div class="flight-box-right">
                                        <div class="flight-modal-name-duration">
                                            <span class="flight-modal-name">{{getAirlineFullName(flightHop.carrier)}}
                                                {{flightHop.flightNumber}}</span>
                                            <span class="flight-modal-duration">{{getHopDuration(flightHop.duration).hrs
                                                + 'h ' + getHopDuration(flightHop.duration).mins + 'm'}}</span>
                                        </div>
                                        <div class="flight-ticket-detail">{{getClassStringWithBrand(i,
                                            flight.legs[num],flightHop)}} {{flightHop.operatingCarrier &&
                                            flightHop.operatingCarrier !== flightHop.carrier ? 'Operated by ' +
                                            getAirlineFullName(flightHop.operatingCarrier) : ''}}</div>
                                        <div class="flight-timing-stops">
                                            <ul>
                                                <li><label>{{getDisplayDate(flightHop.starts) | date:'h:mm a'}}</label>
                                                    <span>{{getAirportCity(flightHop.from)}} {{(flightHop.from)}}</span>
                                                </li>
                                                <li><label>{{getDisplayDate(flightHop.ends) | date:'h:mm a'}}</label>
                                                    <span>{{getAirportCity(flightHop.to)}} {{(flightHop.to)}}</span>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>

                                <div class="flight-layover" *ngIf="flightLayoverInfoList[num][i]">
                                    <span class="flight-layover-left">{{'flightSelected.Layoverin' | translate}}
                                        {{flightLayoverInfoList[num][i].in}}</span>
                                    <span class="flight-layover-right">{{flightLayoverInfoList[num][i].duration.hrs + 'h
                                        ' + flightLayoverInfoList[num][i].duration.mins + 'm'}}</span>
                                </div>

                            </ng-container>

                        </div>
                    </ng-container>
                    <div class="flight-facilities">
                        <ul>
                            <li *ngIf="isPreferredAirline()" [class]="active" style="float:left;">
                                <i class="fare-attr-pref-airline fa fa-plane" aria-hidden="true"></i>
                                <!-- <img class="red-img" src="assets/images/flight-list/airline-red.png" /> -->
                                <span>{{'flightSelected.Airline' | translate}}
                                </span>
                            </li>
                            <li *ngIf="isPreferredAlliance()" [class]="active" style="float:left;">
                                <i class="fare-attr-pref-airline fa fa-plane" aria-hidden="true"></i>
                                <!-- <img class="red-img" src="assets/images/flight-list/airline-red.png" /> -->
                                <span>{{'flightSelected.Alliance' | translate}}
                                </span>
                            </li>
                            <li *ngIf="showWithinPolicy()" [class]="isWithinPolicy()?'active':'not-active'"
                                style="float:left;">
                                <img class="green-img" src="assets/images/flight-list/policy.svg" />
                                <img class="red-img" src="assets/images/flight-list/policy-red.svg" />
                                <span>{{'flightSelected.Policy' | translate}}</span>
                            </li>
                            <li *ngIf="isPreferredAirport()" [class]="active" style="float:left;">
                                <img class="green-img" src="assets/images/flight-list/airport.svg" />
                                <!-- <img class="red-img" src="assets/images/flight-list/airport-red.png" /> -->
                                <span>{{'flightSelected.Airport' | translate}}</span>
                            </li>
                            <li *ngIf="isRedEye()" class="not-active" style="float:left;">
                                <!-- <img class="green-img" src="assets/images/flight-list/airport.png" /> -->
                                <img class="red-img" src="assets/images/flight-list/redeye.svg" />
                                <span>{{'flightSelected.Redeye' | translate}}
                                </span>
                            </li>
                            <li *ngIf="isSmallLayoverFlight()" class="not-active" style="float:left;">
                                <!-- <img class="green-img" src="assets/images/flight-list/airport.png" /> -->
                                <img class="red-img" src="assets/images/flight-list/gen-negative.svg" />
                                <span>{{'flightSelected.Shortlayover' | translate}}
                                </span>
                            </li>
                            <li *ngIf="!isSmallLayoverFlight && isLongLayoverFlight()" class="not-active"
                                style="float:left;">
                                <!-- <img class="green-img" src="assets/images/flight-list/airport.png" /> -->
                                <img class="red-img" src="assets/images/flight-list/gen-negative.svg" />
                                <span>{{'flightSelected.Longlayover' | translate}}
                                </span>
                            </li>
                            <li *ngIf="isChangeOfAirport()" class="not-active" style="float:left;">
                                <!-- <img class="green-img" src="assets/images/flight-list/airport.png" /> -->
                                <img class="red-img" src="assets/images/flight-list/gen-negative.svg" />
                                <span>{{'flightSelected.Changeofairport' | translate}}</span>
                            </li>
                            <li *ngIf="isOverNightFlight()" class="not-active" style="float:left;">
                                <!-- <img class="green-img" src="assets/images/flight-list/airport.png" /> -->
                                <img class="red-img" src="assets/images/flight-list/overnight.svg" />
                                <span>{{'flightSelected.Overnightlayover' | translate}}
                                </span>
                            </li>
                            <li *ngIf="getBaggageDetails()" class="not-active">
                                <i class="fa fa-suitcase" aria-hidden="true"
                                    [ngStyle]="{color: getBaggageDetailsColor()}"></i>
                                <span>{{getBaggageDetails()}}</span>
                            </li>
                        </ul>
                        <div class="flight-modal-price-div text-center">
                            <span class="block flight-price">{{getPrice() | currency :
                                getCurrencySymbol(flight) : 'code': '1.0-0' }}</span>
                            <!-- <span class="block flight-modal-trip-type">Roundtrip</span> -->
                        </div>
                    </div>

                    <!-- <div class="flight-modal-button">
                <button class="btn primary-button" (click)="bookFlight()">SELECT FLIGHT</button>
            </div> -->

                    <div class="flight-feature-list">
                        <ul *ngIf="false">
                            <li>
                                <img class="green-img" src="assets/images/flight-list/ic_check.png" />
                                <span>{{'flightSelected.Seatchoice' | translate}}</span>
                            </li>

                            <li>
                                <img class="green-img" src="assets/images/flight-list/ic_block.png" />
                                <span>{{'flightSelected.Carryonbag' | translate}}</span>
                            </li>

                            <li>
                                <img class="green-img" src="assets/images/flight-list/ic_block.png" />
                                <span>{{'flightSelected.Checkedbag' | translate}}</span>
                            </li>
                        </ul>

                        <ul *ngIf="false">
                            <li>
                                <img class="green-img" src="assets/images/flight-list/ic_check.png" />
                                <span>{{'flightSelected.Cancellations' | translate}}

                                </span>
                            </li>

                            <li>
                                <img class="green-img" src="assets/images/flight-list/ic_block.png" />
                                <span>{{'flightSelected.Changes' | translate}}</span>
                            </li>

                            <li>
                                <img class="green-img" src="assets/images/flight-list/ic_check.png" />
                                <span>{{'flightSelected.PersonalItem' | translate}}</span>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

        </ng-template>