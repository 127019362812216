
import { JsonObject } from '../util/ta-json/src/decorators/json-object';

@JsonObject()
export class HotelQueryParam {
  hotelAddress: string;
  checkinDate: Date;
  checkoutDate: Date;
  currentLocation: string;
  hotelChain: string;
  travellerEmail: string;
  personCount: string;
  roomCount: string;

}
