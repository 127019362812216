
export const EMAIL_QUOTE_HOTELS_RESPONSE = {
  "result": "success",
  "data": {
    "sourceLocation": "LGA",
    "eventList": [
      "zU5EF"
    ],
    "eventIdAndTypeMap": {
      "zU5EF": "hotel"
    },
    "leg1Destination": "SFO",
    "leg2Destination": "LGA",
    "appointmentCity": "Wednesday SFO",
    "meetingStartDate": "March 28",
    "meetingStartTime": " 2:31 PM",
    "meetingEndDate": "March 28",
    "meetingEndTime": " 2:31 PM",
    "policySet": false,
    "totalFlights": "0",
    "tripType": "MULTI_CITY",
    "bookFlightUrl": "http:\/\/qa-web.gallop.ai.s3-website-us-east-1.amazonaws.com\/emailflow?type=hotel&emailId=weqateam120@gmail.com&sToken=d2VxYXRlYW0xMjBAZ21haWwuY29tMTU1Mjk5ODY2NzAyNw&wef=1&ticketNumber=10299&ticketId=173971000017725144&threadId=2022469699&traveller=1&travellerData=%5B%7B%22numberOfAdult%22%3A1%2C%22numberOfChildren%22%3A0%2C%22childrenAges%22%3Anull%2C%22adultsAges%22%3Anull%7D%5D",
    "bookHotelUrl": "http:\/\/qa-web.gallop.ai.s3-website-us-east-1.amazonaws.com\/emailflow?type=hotel&emailId=weqateam120@gmail.com&sToken=d2VxYXRlYW0xMjBAZ21haWwuY29tMTU1Mjk5ODY2NzAyNw&wef=1&ticketNumber=10299&ticketId=173971000017725144&threadId=2022469699&traveller=1&travellerData=%5B%7B%22numberOfAdult%22%3A1%2C%22numberOfChildren%22%3A0%2C%22childrenAges%22%3Anull%2C%22adultsAges%22%3Anull%7D%5D",
    "resendRequestMailToLink": "mailto:test@gallop.ai?subject=Resend%20Trip%20Request&body=Please%20show%20me%20flights%20to%20DEL%20from%20BOM%20arrive%20on%2020th%20Apr",
    "resultDate": "Mar 20, 2019 05:51 AM UTC",
    "hotelOptions": [
      {
        "stay": 1,
        "price": 432.02,
        "rooms": [
          {
            "adultsAges": null,
            "childrenAges": null,
            "numberOfAdult": 1,
            "numberOfChildren": 0
          }
        ],
        "source": "TravelPort",
        "status": null,
        "checkIn": "Mon, Mar 25",
        "eventId": "zU5EF",
        "gsaRate": -1,
        "mapLink": null,
        "message": null,
        "cardType": null,
        "category": "4",
        "checkOut": "Tue, Mar 26",
        "currency": "USD",
        "inPolicy": true,
        "optionId": "miUAS",
        "roomType": null,
        "userName": null,
        "amenities": null,
        "boardType": null,
        "hotelName": "MILLENNIUM HILTON NEW YORK DWNTWN",
        "lattitude": "40.71111399999999",
        "longitude": "-74.010333",
        "priceText": " ",
        "roomPaxes": null,
        "billingAdd": null,
        "checkInISO": "Mon Mar 25 00:00:00 UTC 2019",
        "greatPrice": false,
        "hotelChain": "HH",
        "hotelRooms": [
          {
            "hotelRates": [
              {
                "roomOccupancyDTO": {
                  "adultsAges": null,
                  "childrenAges": null,
                  "numberOfAdult": 1,
                  "numberOfChildren": 0
                }
              }
            ],
            "hotelRoomName": " 1 King Bed Room 42 In Plasma Tv-hi Speed 12.95  Day-mp3 Clock"
          }
        ],
        "last4Digit": null,
        "carDistance": 3,
        "checkOutISO": null,
        "googleImage": null,
        "hotelAdress": "55 Church St, New York, NY 10007, USA",
        "rateComment": null,
        "supplierName": null,
        "amenetiesPerc": 0,
        "dateOfBooking": null,
        "goodAmeneties": false,
        "humalMailLink": "humans@gallop.ai",
        "nameOnTheCard": null,
        "nonRefundable": false,
        "perNightPrice": 432,
        "agencReference": null,
        "hotelChainName": null,
        "preferredbrand": false,
        "hotelBookingRef": null,
        "isUserAmeneties": {
          "AMENITY_TYPE_BAR": false,
          "AMENITY_TYPE_GYM": false,
          "AMENITY_TYPE_POOL": false,
          "AMENITY_TYPE_WIFI": false,
          "AMENITY_TYPE_BKFAST": false,
          "AMENITY_TYPE_RESTAURANT": false,
          "AMENITY_TYPE_ROOMSERVICE": false
        },
        "userPreferences": [
          false,
          false,
          false,
          false,
          false,
          false,
          false
        ],
        "walkingDistance": 8,
        "hotelPhoneNumber": "1-212-693-2001",
        "isHotelAmeneties": {
          "AMENITY_TYPE_BAR": false,
          "AMENITY_TYPE_GYM": false,
          "AMENITY_TYPE_POOL": true,
          "AMENITY_TYPE_WIFI": true,
          "AMENITY_TYPE_BKFAST": true,
          "AMENITY_TYPE_AC_UNIT": false,
          "AMENITY_TYPE_RESTAURANT": false,
          "AMENITY_TYPE_ROOMSERVICE": false
        },
        "isZipCodeRequired": false,
        "supplierVatNumber": null,
        "appointmentAddress": "New York, NY",
        "convenientLocation": false,
        "showWalkingDistance": true,
        "timeFromAirportToHotel": null,
        "timeFromHotelToAirport": null,
        "showFlightForBookedFlight": null,
        "flightAllowedInhotelBooking": false
      },
      {
        "stay": 1,
        "price": 229,
        "rooms": [
          {
            "adultsAges": null,
            "childrenAges": null,
            "numberOfAdult": 1,
            "numberOfChildren": 0
          }
        ],
        "source": "TravelPort",
        "status": null,
        "checkIn": "Mon, Mar 25",
        "eventId": "zU5EF",
        "gsaRate": -1,
        "mapLink": null,
        "message": null,
        "cardType": null,
        "category": "3",
        "checkOut": "Tue, Mar 26",
        "currency": "USD",
        "inPolicy": true,
        "optionId": "lHhMR",
        "roomType": null,
        "userName": null,
        "amenities": null,
        "boardType": null,
        "hotelName": "ALOFT MANHATTAN DOWNTOWN",
        "lattitude": "40.7103938",
        "longitude": "-74.00670350000001",
        "priceText": " ",
        "roomPaxes": null,
        "billingAdd": null,
        "checkInISO": "Mon Mar 25 00:00:00 UTC 2019",
        "greatPrice": false,
        "hotelChain": "AL",
        "hotelRooms": [
          {
            "hotelRates": [
              {
                "roomOccupancyDTO": {
                  "adultsAges": null,
                  "childrenAges": null,
                  "numberOfAdult": 1,
                  "numberOfChildren": 0
                }
              }
            ],
            "hotelRoomName": ""
          }
        ],
        "last4Digit": null,
        "carDistance": 3,
        "checkOutISO": null,
        "googleImage": null,
        "hotelAdress": "49 Ann St # 53, New York, NY 10038, USA",
        "rateComment": null,
        "supplierName": null,
        "amenetiesPerc": 0,
        "dateOfBooking": null,
        "goodAmeneties": false,
        "humalMailLink": "humans@gallop.ai",
        "nameOnTheCard": null,
        "nonRefundable": false,
        "perNightPrice": 229,
        "agencReference": null,
        "hotelChainName": null,
        "preferredbrand": false,
        "hotelBookingRef": null,
        "isUserAmeneties": {
          "AMENITY_TYPE_BAR": false,
          "AMENITY_TYPE_GYM": false,
          "AMENITY_TYPE_POOL": false,
          "AMENITY_TYPE_WIFI": false,
          "AMENITY_TYPE_BKFAST": false,
          "AMENITY_TYPE_RESTAURANT": false,
          "AMENITY_TYPE_ROOMSERVICE": false
        },
        "userPreferences": [
          false,
          false,
          false,
          false,
          false,
          false,
          false
        ],
        "walkingDistance": 6,
        "hotelPhoneNumber": "1 212-5130003",
        "isHotelAmeneties": {
          "AMENITY_TYPE_BAR": true,
          "AMENITY_TYPE_GYM": false,
          "AMENITY_TYPE_POOL": false,
          "AMENITY_TYPE_WIFI": true,
          "AMENITY_TYPE_BKFAST": false,
          "AMENITY_TYPE_AC_UNIT": false,
          "AMENITY_TYPE_RESTAURANT": false,
          "AMENITY_TYPE_ROOMSERVICE": false
        },
        "isZipCodeRequired": false,
        "supplierVatNumber": null,
        "appointmentAddress": "New York, NY",
        "convenientLocation": false,
        "showWalkingDistance": true,
        "timeFromAirportToHotel": null,
        "timeFromHotelToAirport": null,
        "showFlightForBookedFlight": null,
        "flightAllowedInhotelBooking": false
      },
      {
        "stay": 1,
        "price": 260.24,
        "rooms": [
          {
            "adultsAges": null,
            "childrenAges": null,
            "numberOfAdult": 1,
            "numberOfChildren": 0
          }
        ],
        "source": "TravelPort",
        "status": null,
        "checkIn": "Mon, Mar 25",
        "eventId": "zU5EF",
        "gsaRate": -1,
        "mapLink": null,
        "message": null,
        "cardType": null,
        "category": "3",
        "checkOut": "Tue, Mar 26",
        "currency": "USD",
        "inPolicy": true,
        "optionId": "U1ddc",
        "roomType": null,
        "userName": null,
        "amenities": null,
        "boardType": null,
        "hotelName": "RESIDENCE INN DOWNTWN MARRIOTT",
        "lattitude": "40.7095628",
        "longitude": "-74.0099212",
        "priceText": " ",
        "roomPaxes": null,
        "billingAdd": null,
        "checkInISO": "Mon Mar 25 00:00:00 UTC 2019",
        "greatPrice": false,
        "hotelChain": "RC",
        "hotelRooms": [
          {
            "hotelRates": [
              {
                "roomOccupancyDTO": {
                  "adultsAges": null,
                  "childrenAges": null,
                  "numberOfAdult": 1,
                  "numberOfChildren": 0
                }
              }
            ],
            "hotelRoomName": " Rules, Studio, 1 King"
          }
        ],
        "last4Digit": null,
        "carDistance": 3,
        "checkOutISO": null,
        "googleImage": null,
        "hotelAdress": "170 Broadway, New York, NY 10038, USA",
        "rateComment": null,
        "supplierName": null,
        "amenetiesPerc": 0,
        "dateOfBooking": null,
        "goodAmeneties": false,
        "humalMailLink": "humans@gallop.ai",
        "nameOnTheCard": null,
        "nonRefundable": false,
        "perNightPrice": 260,
        "agencReference": null,
        "hotelChainName": null,
        "preferredbrand": false,
        "hotelBookingRef": null,
        "isUserAmeneties": {
          "AMENITY_TYPE_BAR": false,
          "AMENITY_TYPE_GYM": false,
          "AMENITY_TYPE_POOL": false,
          "AMENITY_TYPE_WIFI": false,
          "AMENITY_TYPE_BKFAST": false,
          "AMENITY_TYPE_RESTAURANT": false,
          "AMENITY_TYPE_ROOMSERVICE": false
        },
        "userPreferences": [
          false,
          false,
          false,
          false,
          false,
          false,
          false
        ],
        "walkingDistance": 8,
        "hotelPhoneNumber": "1 212-6008900",
        "isHotelAmeneties": {
          "AMENITY_TYPE_BAR": false,
          "AMENITY_TYPE_GYM": false,
          "AMENITY_TYPE_POOL": false,
          "AMENITY_TYPE_WIFI": true,
          "AMENITY_TYPE_BKFAST": true,
          "AMENITY_TYPE_AC_UNIT": false,
          "AMENITY_TYPE_RESTAURANT": false,
          "AMENITY_TYPE_ROOMSERVICE": false
        },
        "isZipCodeRequired": false,
        "supplierVatNumber": null,
        "appointmentAddress": "New York, NY",
        "convenientLocation": false,
        "showWalkingDistance": true,
        "timeFromAirportToHotel": null,
        "timeFromHotelToAirport": null,
        "showFlightForBookedFlight": null,
        "flightAllowedInhotelBooking": false
      }
    ],
    "ticketId": "173971000017725144",
    "tripId": "10299xC0muQdo",
    "appointment_location": "Wednesday SFO",
    "hotelOptionsList": [
      [
        {
          "eventId": "zU5EF",
          "source": "TravelPort",
          "optionId": "miUAS",
          "userName": null,
          "hotelAdress": "55 Church St, New York, NY 10007, USA",
          "category": "4",
          "hotelChain": "HH",
          "hotelChainName": null,
          "checkIn": "Mon, Mar 25",
          "checkOut": "Tue, Mar 26",
          "checkInISO": "Mon Mar 25 00:00:00 UTC 2019",
          "checkOutISO": null,
          "status": null,
          "walkingDistance": 8,
          "carDistance": 3,
          "amenetiesPerc": 0,
          "appointmentAddress": "New York, NY",
          "lattitude": "40.71111399999999",
          "priceText": " ",
          "longitude": "-74.010333",
          "userPreferences": [
            false,
            false,
            false,
            false,
            false,
            false,
            false
          ],
          "googleImage": null,
          "perNightPrice": 432,
          "amenities": null,
          "isHotelAmeneties": {
            "AMENITY_TYPE_BAR": false,
            "AMENITY_TYPE_GYM": false,
            "AMENITY_TYPE_POOL": true,
            "AMENITY_TYPE_WIFI": true,
            "AMENITY_TYPE_BKFAST": true,
            "AMENITY_TYPE_AC_UNIT": false,
            "AMENITY_TYPE_RESTAURANT": false,
            "AMENITY_TYPE_ROOMSERVICE": false
          },
          "isUserAmeneties": {
            "AMENITY_TYPE_BAR": false,
            "AMENITY_TYPE_GYM": false,
            "AMENITY_TYPE_POOL": false,
            "AMENITY_TYPE_WIFI": false,
            "AMENITY_TYPE_BKFAST": false,
            "AMENITY_TYPE_RESTAURANT": false,
            "AMENITY_TYPE_ROOMSERVICE": false
          },
          "greatPrice": false,
          "nonRefundable": false,
          "inPolicy": true,
          "preferredbrand": false,
          "convenientLocation": false,
          "goodAmeneties": false,
          "flightAllowedInhotelBooking": false,
          "timeFromAirportToHotel": null,
          "timeFromHotelToAirport": null,
          "showFlightForBookedFlight": null,
          "showWalkingDistance": true,
          "dateOfBooking": null,
          "gsaRate": -1,
          "isZipCodeRequired": false,
          "rooms": [
            {
              "numberOfAdult": 1,
              "numberOfChildren": 0,
              "childrenAges": null,
              "adultsAges": null
            }
          ],
          "mapLink": null,
          "hotelRooms": [
            {
              "hotelRoomName": " 1 King Bed Room 42 In Plasma Tv-hi Speed 12.95  Day-mp3 Clock",
              "hotelRates": [
                {
                  "roomOccupancyDTO": {
                    "numberOfAdult": 1,
                    "numberOfChildren": 0,
                    "childrenAges": null,
                    "adultsAges": null
                  }
                }
              ]
            }
          ],
          "roomPaxes": null,
          "stay": 1,
          "currency": "USD",
          "price": 432.02,
          "hotelName": "MILLENNIUM HILTON NEW YORK DWNTWN",
          "hotelBookingRef": null,
          "agencReference": null,
          "roomType": null,
          "boardType": null,
          "rateComment": null,
          "nameOnTheCard": null,
          "billingAdd": null,
          "cardType": null,
          "humalMailLink": "humans@gallop.ai",
          "supplierName": null,
          "supplierVatNumber": null,
          "last4Digit": null,
          "hotelPhoneNumber": "1-212-693-2001",
          "message": null
        },
      ]
    ],
    "flightLegRequests": [
      {
        "combo": false,
        "origin": "LGA",
        "dateTime": "2018-03-28T14:31:00.000-04:00",
        "dateTime2": "2018-03-28T14:31:00.000-04:00",
        "destinType": "TYPE_AIRPORT",
        "originType": "TYPE_AIRPORT",
        "destination": "SFO",
        "locationQualifier": "LOCATION_AT_DESTINATION_AIRPORT"
      },
      {
        "combo": false,
        "origin": "SFO",
        "dateTime": "2018-03-28T14:31:00.000-04:00",
        "dateTime2": "2018-03-28T14:31:00.000-04:00",
        "destinType": "TYPE_AIRPORT",
        "originType": "TYPE_AIRPORT",
        "destination": "LGA",
        "locationQualifier": "LOCATION_AT_DESTINATION_AIRPORT"
      }
    ],
    "name": "Robin",
    "view_more_url": "mailto:test@gallop.ai?subject=Re%3A%5B%23%2310299%23%23%5Dtest%201&body=Please%20show%20me%20more%20hotel%20options%20in%20",
    "flight_pref_list": [

    ],
    "isUserSelected": false
  }
}
