import { Injectable } from "@angular/core";

@Injectable()
export class UserCredentialsService {
    userid: string;
    sToken: string;
    selelang='en';

    public getUserId() {
        return this.userid;
    }
    public getSToken() {
        return this.sToken;
    }

    public setUserId(input: string) {
        this.userid = input;
    }
    public getLang() {
        return this.selelang;
    }

    public setLang(input: string) {
        this.selelang = input;
    }
    public setSToken(input: string) {
        this.sToken = input;
    }

}