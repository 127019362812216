import { NgxAnalytics } from 'ngx-analytics';

export class GallopAnalyticsUtil {
  constructor() {
  }
  static trackAction(ngxAnalytics: NgxAnalytics, action: string) {
    ngxAnalytics.eventTrack.next({
      action: action,
    });
  }

  static trackActionWithCategory(ngxAnalytics: NgxAnalytics, action: string, category: string) {
    ngxAnalytics.eventTrack.next({
      action: action,
      properties: {
        category: category,
      },
    });
  }
  static trackActionWithCategoryAndLabel(ngxAnalytics: NgxAnalytics, action: string, category: string, label: string) {
    ngxAnalytics.eventTrack.next({
      action: action,
      properties: {
        category: category,
        label: label,
      },
    });
  }

}
