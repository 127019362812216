
import { HotelAmenity } from './hotel-amenity';
import { TraflaImage } from './trafla-image';

export class HotelResult {
    public optionSelectionReason: string;
    public thumbnailURL: string;
    public hotelName: string;
    public address: string;
    public marketingText;
    public companyRecommended: boolean;
    public hotelChainCode: string;
    public hotelChainName: string;
    public drivingTime: number;
    public distanceMile: string;
    public walkingTime: number;
    public price: number;
    public currency: string;
    public hotelInfo: any;
    public displayCurrency:string;
    public displayPrice:number;
    public displayTax:number;
    public displayResortFee:number;
    public handleType: string;
    public starRating: string;
    public ups: number;
    public downs: number
    public score: number
    public amenities: Map<string, HotelAmenity>;
    public withinPolicy: boolean;
    public latitude: number;
    public longitude: number;
    public thumbnailImage: TraflaImage;
    public checkInDate: Date;
    public checkOutDate: Date;
    public rateId: string;
    public hotelCode: string;
    public recommendedIdx: number;
    public hotelImages: any;

    public constructor(fields?: any) {

        if (fields) {
            Object.assign(this, fields);
        }
    }
}


export class priceChange {
    hotelCode: null;
    price: null;
    inPolicy: null;
}