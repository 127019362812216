export class UserAccountInputs {
  public email: string;
  public firstName: string;

  public lastName: string;

  public middleName: string;
  public alt_emails: Array<string>;

  public newPassword: string;

  public constructor(emailId: string, fName: string, lName: string, mName: string, alEmails: Array<string>) {
    this.email = emailId;
    this.firstName = fName;
    this.lastName = lName;
    this.middleName = mName;
    if (this.middleName === ' ')
      this.middleName = '';
    this.alt_emails = alEmails;

  }


}