export class UserPreferences {
  public preferredAirlines: Array<string> = [];
  public preferredClass: Array<string> = [];
  public homeAirport: Array<string> = [];
  public preferredHotelClass: Array<string> = [];
  public preferredHotelChains: Array<string> = [];
  public rentalCarPreferences: any;
  public seatPreference: string;
  public homeAddress: string;

  public constructor(fields?: any) {

    if (fields) {
      Object.assign(this, fields);
    }
  }
}
