<ng-container #popupmodal>
  <div class="modal-header">
    <h5 class="modal-title" id="myModalLabel">
      {{popUpTitle}}
    </h5>
    <button type="button" class="close" data-dismiss="modal" (click)="onModelCancel()">
      <i class="material-icons" style="color:white;">close</i>
    </button>
  </div>
  <div class="modal-body">
    <div class="msg" style="white-space: pre-wrap;">{{bodyMsg}}</div>
    <div class="modal-form-button">
      <a class="link" (click)="onPopUPCTAClick()">{{link}}</a>
    </div>
  </div>
</ng-container>