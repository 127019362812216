<div #modal class="show" id="confirmBookingModal" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
    <div class="table-view">
        <div class="table-cell-view">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="myModalLabel">
                            <img src="assets/images/footer-logo.png" alt="logo" />
                        </h5>
                        <!-- <button type="button" class="close" data-dismiss="modal"(click)="hide()">
                            <i class="material-icons">close</i>
                        </button>-->
                    </div>
                    <div class="modal-body">
                        <div class="modal-icon-container">
                            <span class="modal-icon">
                                <i class="material-icons">check</i>
                            </span>
                        </div>
                        <div class="modal-content-heading">
                            <h3>{{message}}</h3>
                        </div>
                        <div *ngIf="message2" class="modal-button-wrapper">
                            <!-- <button *ngIf="message2==='NotAllowedToProceed'"type="button" name="buttonName" class="btn btn-default modal-button">Not Allowed To Proceed</button> -->
                            <button *ngIf="message2==='AllowedToProceed'" type="button" name="buttonName"
                                (click)="onCancel()" class="btn btn-default modal-button">{{'option.OK' | translate}}</button>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
</div>