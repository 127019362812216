<div style="position:relative;" (click)="handleClickEventForImplementingModalHack()" [ngStyle]="changeStyle1()">
    <ng-progress id="quick" [spinner]="false" [trickleSpeed]="1000" [speed]="1000" [color]="'#03efd8'"
        #progressBarQuick></ng-progress>
    <ng-progress id="detail" [spinner]="false" [trickleSpeed]="1500" [speed]="1500" [color]="'#8A27F7'"
        #progressBarDetail></ng-progress>

    <ng-progress id="payment" [spinner]="false" [trickleSpeed]="1000" [speed]="1000" [color]="'#03efd8'"
        #progressBarPayment></ng-progress>
    <ng-progress id="book" [spinner]="false" [trickleSpeed]="1500" [speed]="1500" [color]="'#8A27F7'" #progressBarBook>
    </ng-progress>
    <div style="display: flex;" [ngStyle]="changeStyle()">
        <router-outlet></router-outlet>
    </div>
    <div class="help-Popup" [ngStyle]="{'z-index':airportModalOpen() ? '0':'1100'}">
        <app-help-popup *ngIf="(this.isMobile1 && isuserHascard()) || !njoySpecificBuild"></app-help-popup>
    </div>
    <app-footer></app-footer>
</div>
<div *ngIf="this.currDatePicker!==null && (isMobile && !isDashboardPage())" class="calendar-close-icon"
    (click)="closeCalendar()">
    <i class="material-icons">close</i>
</div>