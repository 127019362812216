import { Component, OnInit } from '@angular/core';
import { ToastrService, ActiveToast } from 'ngx-toastr';
import {
  FormArray, FormBuilder, FormControl, FormGroup,
  Validators,
  AbstractControl,
  ValidationErrors,
  ValidatorFn
} from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { UserAccountInfo } from '../entity/user-account-info';
import { UserAccountService } from '../user-account.service';
import { deserialize } from '../util/ta-json/src/methods/deserialize';
import { Subscription } from 'rxjs';
import { environment } from '../../environments/environment';
import { UserAccountInputs } from '../entity/user-account-inputs';
import { Constants } from '../util/constants';

@Component({
  selector: 'user-account',
  templateUrl: './user-account.component.html',
  styleUrls: ['./user-account.component.scss']
})
// This is not being used any more
export class UserAccountComponent implements OnInit {
  userEmailId: string;
  sToken: string;
  isChangePasswordMode: boolean;
  fetchAccountInfoSubscription: Subscription;
  userAccountInfoObj: UserAccountInfo;
  contactForm: FormGroup;
  alterEmailsForm: FormArray;
  activeToast: ActiveToast<any>;
  createFormGroup() {
    return new FormGroup({
      usrAccInputs: new FormGroup({
        firstName: new FormControl('', [Validators.compose([Validators.required, Validators.pattern(Constants.RGEX_ONLY_ALPHA)])]),
        middleName: new FormControl('', [Validators.pattern(Constants.RGEX_ONLY_ALPHA)]),
        lastName: new FormControl('', [Validators.compose([Validators.required, Validators.pattern(Constants.RGEX_ONLY_ALPHA)])]),
        // email: new FormControl('',[Validators.required, Validators.pattern(Constants.RGEX_EMAIL)]),
        alt_emails: new FormArray([]),
        newPassword: new FormControl('', [this.newPasswordValidator.bind(this)]),
        confirmPassword: new FormControl('', [this.confirmPasswordValidator.bind(this)]),
      }),
    });
  }



  passengersForm: FormGroup;

  // constructor(private fb: FormBuilder,
  //   private searchResultService: SearchResultService,
  //   private userAccountInfoService: UserAccountService,
  //   private searchService: SearchService,
  //   public router: Router,
  //   private bookingService:BookingService,
  //   private geoLocationService:GeoLocationService) {
  // }

  constructor(private toastr: ToastrService, private formBuilder: FormBuilder, private activatedRoute: ActivatedRoute,
    private userAccountInfoService: UserAccountService, public router: Router) {
    // this.contactForm = this.createFormGroupWithBuilderAndModel(formBuilder);
    this.contactForm = this.createFormGroup();
    this.userAccountInfoObj = new UserAccountInfo();


  }


  setContactForm(usrAccntInputs: UserAccountInputs) {
    this.contactForm.reset({ usrAccInputs: usrAccntInputs });
  }
  ngOnInit() {

    this.fetchAccountInfoSubscription = this.userAccountInfoService.userAccountInfoObjObserver$.subscribe((userAccountInfo: UserAccountInfo) => {
      // this.userAccountInfoObj = userAccountInfo;
      this.fetchQuick();
    });

    this.isChangePasswordMode = false;
    this.alterEmailsForm = (<FormArray>(<FormGroup>this.contactForm.controls['usrAccInputs']).controls['alt_emails']);
    this.activatedRoute.queryParams.subscribe(params => {
      this.userEmailId = params['emailId'];
      this.sToken = params['sToken'];
    });
    this.fetchQuick();
    // if(this.userEmailId && this.userEmailId.length > 0 && this.sToken && this.sToken.length > 0){

    // }


  }



  showSuccess() {
    this.toastr.remove(this.activeToast.toastId);
    this.activeToast = this.toastr.success('Successful!', 'Profile saved successfully');
  }

  showProgress() {
    if (this.activeToast) {
      this.toastr.remove(this.activeToast.toastId);
    }
    this.activeToast = this.toastr.info('Please wait!', 'Saving....');
  }


  subscribeEvents() {
  }





  unsubscribeEvents() {

  }

  ngOnDestroy() {

  }


  preventCheck(event) {
    event.preventDefault();
  }

  fetchQuick() {
    this.userAccountInfoObj = this.userAccountInfoService.getAccountInfo();
    if (!this.userAccountInfoObj) {
      return;
    }
    // this.fetchAccountInfoSubscription = this.userAccountInfoService.requestAccountInfo(userEmail,sToken).subscribe(res => {
    // if (res.success == true) {
    //   this.userAccountInfoObj = deserialize(res.data, UserAccountInfo);

    let usrAccInputs: UserAccountInputs = new UserAccountInputs(
      this.userAccountInfoObj.userInfo.email,
      this.userAccountInfoObj.userInfo.firstname,
      this.userAccountInfoObj.userInfo.lastname,
      this.userAccountInfoObj.userInfo.middlename,
      this.userAccountInfoObj.userInfo.alt_emails);
    // let googleInfoJSON = JSON.parse(this.userAccountInfoObj.userInfo.userEntityData.google_info);
    // if(googleInfoJSON.image && googleInfoJSON.image.url){
    //   this.userAccountInfoObj.userInfo.profileImageUrl = googleInfoJSON.image.url;
    // }
    this.resetAlterEmails();
    for (var e in this.userAccountInfoObj.userInfo.alt_emails) {
      this.addEmailBox(this.userAccountInfoObj.userInfo.alt_emails[e]);
    }
    this.addEmailBox('');
    this.setContactForm(usrAccInputs);
    // }else if (res.status == 'error') {
    //   this.router.navigate(['/errors/404']);
    // }else{
    //   this.router.navigate(['/errors/404']);
    // }
    // });

  }

  public isFreeSubscription() {
    if (this.userAccountInfoObj && this.userAccountInfoObj !== null && this.userAccountInfoObj.userInfo) {
      return true;
    } else {
      return true;
    }
  }

  public getSubscriptionExpiryDate() {
    if (this.userAccountInfoObj && this.userAccountInfoObj !== null && this.userAccountInfoObj.userInfo) {
      return this.userAccountInfoObj.userInfo.userEntityData.subscription.expiryDate;
    } else {
      return "";
    }
  }

  public getSubscriptionPlanName() {
    if (this.userAccountInfoObj && this.userAccountInfoObj !== null && this.userAccountInfoObj.userInfo) {
      return this.userAccountInfoObj.userInfo.userEntityData.subscription.plan.name;
    } else {
      return "Free";
    }
  }

  public getGallopCash() {
    if (this.userAccountInfoObj && this.userAccountInfoObj !== null && this.userAccountInfoObj.gallopCash) {
      return this.userAccountInfoObj.gallopCash.amount;
    } else {
      return "0.00";
    }
  }

  public getCardList() {
    if (this.userAccountInfoObj && this.userAccountInfoObj !== null && this.userAccountInfoObj.cardList) {
      return this.userAccountInfoObj.cardList.card_list;
    }
  }

  public getAddCardURL() {
    return environment.uiAddCardUrl + '?emailId=' + this.userEmailId + '&sToken=' + this.sToken;
  }

  onSubmit() {
    // Make sure to create a deep copy of the form-model
    this.validateAllFormFields(this.contactForm);
    if (this.contactForm.invalid) {
      return;
    }

    this.showProgress();
    let inputs: UserAccountInputs = Object.assign({}, this.contactForm.controls['usrAccInputs'].value);
    this.userAccountInfoObj.userInfo.firstname = inputs.firstName;
    this.userAccountInfoObj.userInfo.middlename = inputs.middleName;
    this.userAccountInfoObj.userInfo.lastname = inputs.lastName;
    if (inputs.middleName && inputs.middleName.length == 0) {
      this.userAccountInfoObj.userInfo.middlename = ' ';
    }
    this.userAccountInfoObj.userInfo.firstName = this.userAccountInfoObj.userInfo.firstname;
    this.userAccountInfoObj.userInfo.middleName = this.userAccountInfoObj.userInfo.middlename;
    this.userAccountInfoObj.userInfo.lastName = this.userAccountInfoObj.userInfo.lastname;
    this.userAccountInfoObj.userInfo.alt_emails = new Array<string>();
    for (let email of inputs.alt_emails) {
      if (email) {
        this.userAccountInfoObj.userInfo.alt_emails.push(email);
      }
    }
    if (this.isChangePasswordMode) {
      this.userAccountInfoObj.userInfo.userEntityData.password = inputs.newPassword;
    } else {
      this.userAccountInfoObj.userInfo.userEntityData.password = null;
    }
    this.fetchAccountInfoSubscription = this.userAccountInfoService.saveAccountInfo(this.userAccountInfoObj.userInfo).subscribe(res => {
      if (res.success == true) {
        this.isChangePasswordMode = false;
        this.userAccountInfoObj = deserialize(res.data, UserAccountInfo);

        let usrAccInputs: UserAccountInputs = new UserAccountInputs(
          this.userAccountInfoObj.userInfo.email,
          this.userAccountInfoObj.userInfo.firstname,
          this.userAccountInfoObj.userInfo.lastname,
          this.userAccountInfoObj.userInfo.middlename,
          this.userAccountInfoObj.userInfo.alt_emails);
        this.resetAlterEmails();
        for (var e in this.userAccountInfoObj.userInfo.alt_emails) {
          this.addEmailBox(this.userAccountInfoObj.userInfo.alt_emails[e]);
        }
        this.addEmailBox('');
        this.setContactForm(usrAccInputs);
        this.showSuccess();
        // this.gallopCash =  this.userAccountInfoObj.gallopCash.amount;
      } else if (res.status == 'error') {
        this.router.navigate(['/errors/404']);
      } else {
        this.router.navigate(['/errors/404']);
      }
    });
  }

  public setChangePasswordMode() {
    this.isChangePasswordMode = true;
  }

  public cancelChangePasswordMode() {
    this.isChangePasswordMode = false;
    this.getFormControl('newPassword').updateValueAndValidity();
    this.getFormControl('confirmPassword').updateValueAndValidity();
  }

  public getFormControl(fieldName: string) {
    return (<FormGroup>this.contactForm.controls['usrAccInputs']).controls[fieldName];
  }

  public addEmailBox(email: string) {
    this.alterEmailsForm.push(new FormControl(email, [Validators.pattern(Constants.RGEX_EMAIL)]));
  }

  public removeEmailBox(index: number) {
    this.alterEmailsForm.removeAt(index);
  }

  public resetAlterEmails() {
    this.alterEmailsForm.controls = [];
  }


  conditionalValidators(condition, validators: ValidatorFn[]) {
    if (condition) {
      return Validators.compose(validators);
    }
    return null;
  }

  confirmPasswordValidator(control: AbstractControl): ValidationErrors | null {
    let result = null;

    if (this.isChangePasswordMode) {
      if (control.value && control.valid) {
        let newPass = this.getFormControl('newPassword').value;
        let confirmPassControl = this.getFormControl('confirmPassword');
        if ((confirmPassControl.dirty || confirmPassControl.touched) && confirmPassControl.value !== newPass) {
          result = { 'passwordMisMatch': true };
        }
      } else {
        result = { 'required': true };
      }
    }
    return result;
  }

  newPasswordValidator(control: AbstractControl): ValidationErrors | null {
    let result = null;

    if (this.isChangePasswordMode) {
      if (control.value && control.valid) {
        let newPass = this.getFormControl('newPassword').value;
        if (newPass.length < 8) {
          result = { 'minlength': true };
        }
      } else {
        result = { 'required': true };
      }
    }
    return result;
  }
  public goToFlightBookingUI() {
    let userid = this.userEmailId;
    let sToken = this.sToken;
    window.location.href = environment.webBookingUrl + "?userid=" + userid + "&sToken=" + sToken;
  }

  public getProfileImage() {
    if (this.userAccountInfoObj.userInfo && this.userAccountInfoObj.userInfo.profileImageUrl) {
      return this.userAccountInfoObj.userInfo.profileImageUrl;
    }
    return null;
  }

  validateAllFormFields(formGroup: FormGroup) {         //{1}
    Object.keys(formGroup.controls).forEach(field => {  //{2}
      const control = formGroup.get(field);             //{3}
      if (control instanceof FormControl) {             //{4}
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {        //{5}
        this.validateAllFormFields(control);            //{6}
      }
    });
  }
}
