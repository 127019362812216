
import { TravelerDetails } from './traveler-details';
import { PaymentInstrumentDetails } from './payment-instrument-details';
import { FlightResult } from './flight-result';
import { FlightSearchRequest } from './flight-search-request';

export class RevalidateRequest {

  flightsToBook: { flights: FlightResult[] };
  searchQuery: FlightSearchRequest;
  tripId: string;
  ticketId: string;
  eventId: string;
  optionId: string;
  threadId: string;
  public constructor(fields?: any) {

    if (fields) {
      Object.assign(this, fields);
    }
  }
}
