import { PassportDetails } from './passport-details';
import { FrequentFlyerInfo } from './frequent-flyer-info';

export class AddEmployeeDTO {
  firstName: string;
  middleName: string;
  lastName: string;
  gender: string;
  dateOfBirth: string;
  email: string;
  phoneNumber: string;
  approvers: Array<any>;
  jobTitle: String;
  managerName: String;
  managerEmail: String;
  departmentId: String;
  companyCardAccess: boolean;

  public constructor(fields?: any) {

    if (fields) {
      Object.assign(this, fields);
    }
  }
}
