import { Component, OnInit, ViewChild } from '@angular/core';
import { BsModalService, ModalDirective } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-confirm-message-modal',
  templateUrl: './confirm-message-modal.component.html',
  styleUrls: ['./confirm-message-modal.component.scss']
})
export class ConfirmMessageModalComponent implements OnInit {

  @ViewChild('modal', { static: true }) modal: ModalDirective;
  constructor(
    private modalService: BsModalService,
    public bsModalRef: BsModalRef) { }

  ngOnInit() {
  }

  hide() {
    this.bsModalRef.hide();
  }
}
