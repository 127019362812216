<div *ngIf="(this.isMobile1 && isuserHascard()) || !njoySpecificBuild" class="support" id="helpModal">
    <div class="helpBox" (click)="openHelpPopup()" onclick="openModal();">
        <i class="fa fa-comments"
            style="color:blue;position: relative;font-size: 14px;padding-top:8px;padding-left: 15px;"></i>
        <span style="margin-left: 4px;color:blue;padding-right: 15px;font-family: 'apercu-b'"> {{ 'help.Help' | translate }}</span>
    </div>
    <div id="helpPoupupWrapper" style="display:none">
        <ngx-smart-modal (onOpen)="handleModalEvents('onOpen', 'helpModal')" [hideDelay]="0"
            (onClose)="handleModalEvents('onClose', 'helpModal')"
            (onDismiss)="handleModalEvents('onDismiss', 'helpModal')" [closable]="false" #helpModal
            identifier="helpModal">

            <div class="modal-container flight-modal-container filter-modal1 modalAirportFilterInfo"
                (click)="$event.stopPropagation();">
                <div class="modal-header">
                    {{ 'help.Support' | translate }}
                    <button type="button" class="close" data-dismiss="modal" (click)="onSmartModelCancel('helpModal')">
                        <i class="material-icons">close</i>
                    </button>
                </div>

                <div class="modal-body">
                    <app-support [searchPopClicked]="searchPopClicked"></app-support>
                </div>
            </div>
        </ngx-smart-modal>
    </div>
</div>