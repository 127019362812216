import { Component, OnInit } from '@angular/core';
import { UserAccountService } from '../user-account.service';
import { UserCredentialsService } from '../user-credentials.service';
import { CommonUtils } from '../util/common-utils';
import { GallopAnalyticsUtil } from '../analytics.service';
import { TranslateService } from '@ngx-translate/core';
import { NgxAnalytics } from 'ngx-analytics';
import { UserAccountInfo } from '../entity/user-account-info';
declare var mixPanelLanguageChanged: any;
@Component({
  selector: 'app-super-header',
  templateUrl: './super-header.component.html',
  styleUrls: ['./super-header.component.scss']
})
export class SuperHeaderComponent implements OnInit {

  constructor(public userAccountService: UserAccountService,
    private userCredentials: UserCredentialsService, private translate: TranslateService,  private ngxAnaltics: NgxAnalytics,) { }

    languageSelected='English';
    languageOptions=[{id:'en',value:'English'},{id:'es',value:'Spanish'},{id:'fr',value:'French'},{id:'it',value:'Italian'}]
  ngOnInit(): void {
    
     this.userAccountService.userAccountInfoObjObserver$.subscribe((userProfile: UserAccountInfo) => {

      if (userProfile) {
        const selectedLang = localStorage.getItem('selectedLanguage');
        let findIndex= this.languageOptions.findIndex(item => item.id ===selectedLang);
          if(this.userAccountService.showLanguageOptions && findIndex !==-1){
            this.languageSelected = this.languageOptions[findIndex].value;
          }
      }
    });
  }
  useLanguage(language: string) {
    this.translate.use(language);
    this.userCredentials.setLang(language);
    const oldLang: string = localStorage.getItem('selectedLanguage');
    localStorage.setItem('selectedLanguage', language);
    if (oldLang) {
      localStorage.setItem('oldLanguage', oldLang);
    }
    mixPanelLanguageChanged(language, oldLang);
    GallopAnalyticsUtil.trackActionWithCategory(this.ngxAnaltics, 'languageChanged', 'WebSearchUI');
  }
  onlanguageChange(event){
    console.log("event",event);
    this.languageSelected = event.value;
    this.useLanguage(event.id) ;
  }
  getSelectedLanguage() {
    const selectedLang = localStorage.getItem('selectedLanguage');
    if (selectedLang) {
      return selectedLang;
    } else {
      const userLang = CommonUtils.getUserPreferredLanguage(this.translate);
      if (userLang !== this.translate.currentLang) {
        this.translate.use(userLang);
      }
      return userLang;
    }
  }
  convertFile() {
    this.userAccountService.convertLocalizationFile();
  }
}
