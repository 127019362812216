import { Component, OnInit, Input } from '@angular/core';
import { ClientConfiguration } from '../client-config.service';
import { UserAccountService } from '../user-account.service';
import { Constants } from '../util/constants';
declare var removeNavigationComponentPadding: any;
@Component({
  selector: 'app-support',
  templateUrl: './support.component.html',
  styleUrls: ['./support.component.scss']
})
export class SupportComponent implements OnInit {
  njoySpecificBuild: boolean;
  
  constructor(public userAccountInfoService: UserAccountService,
    private clientConfig: ClientConfiguration) {
    this.njoySpecificBuild = this.userAccountInfoService.isItNjoyBuild();
  }
  contactNumber= Constants.GALLOP_HELP_CONTACT_NO;
  @Input() searchPopClicked = false;
  changeStyle() {
    if (this.searchPopClicked) {
      return { 'border': 'none', 'height': '600px', 'box-shadow': 'none' };
    } else {
      return { 'border': '1px solid #E1E1E1', 'height': 'height: calc(100vh - 75px)', 'box-shadow': '0 0 20px 0 #edfafc' }
    }
  }
  ngOnInit() {
    setTimeout(() => {
      removeNavigationComponentPadding();
    }, 500);

  }
  getPhoneNo(): any {
    return Constants.GALLOP_HELP_CONTACT_NO;
  }
  getEmailId(): any {
    if (this.clientConfig.agentEmailId && this.clientConfig.agentEmailId.trim().length > 0) {
      return this.clientConfig.agentEmailId;
    }
    return 'trip@routespring.com';
  }

}
