import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { BookingService } from '../booking.service';
import { environment } from '../../environments/environment';
import { BookingResponse } from '../entity/booking-response';
import { deserialize } from '../util/ta-json/src/methods/deserialize';
import { BookingMessageModalComponent } from '../booking-message-modal/booking-message-modal.component';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { NgProgress } from '@ngx-progressbar/core';
import { Constants } from '../util/constants';
import { FormUtils } from '../util/form-utils';
import { Location } from '@angular/common';
import { ConfirmMessageModalComponent } from '../confirm-message-modal/confirm-message-modal.component';
import { BookingResponseErrorType } from '../enum/booking-response-error.type';
import { GallopLocalStorageService } from '../gallop-local-storage.service';

declare const Stripe: any;

@Component({
  selector: 'payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.scss']
})
export class PaymentComponent implements OnInit {


  @Input() noOfPassengers: number;
  transactionAmount: number;

  public checkoutForm: FormGroup;
  confirmModalRef: BsModalRef;

  @ViewChild('card', { static: true }) cardElement: ElementRef;

  card: any;

  stripe = Stripe(environment.stripeAPIKey);
  stripeElements = this.stripe.elements();

  bookFlightSubscription: Subscription;
  flightFareChangeSubscription: Subscription;


  bsModalRef: BsModalRef;

  disablePay: boolean = false;

  constructor(private formBuilder: FormBuilder,
    private gallopLocalStorage: GallopLocalStorageService,
    private bookingService: BookingService,
    private modalService: BsModalService,
    public progressBar: NgProgress,
    public location: Location) {

    window.addEventListener('beforeunload', this.showConfirmationPopup);
    // ON BACK BUTTON PRESS HANDLING
    // window.onpopstate = (e) => {
    //   if(this.disablePay) {
    //     alert('Your request is being processed');
    //   }
    // }
  }

  showConfirmationPopup(e: Event) {
    // Cancel the event as stated by the standard.
    e.preventDefault();
    // Chrome requires returnValue to be set.
    e.returnValue = false;
  }

  ngOnInit() {

    this.checkoutForm =
      this.formBuilder.group(
        {
          name: ['', Validators.compose([Validators.required, Validators.pattern(Constants.RGEX_ONLY_ALPHA_AND_SPACE)])],
          card: ['', Validators.required]
        });

    this.flightFareChangeSubscription = this.bookingService.flightFareChangeSubject.subscribe((newTransactionAmount) => {

      if (newTransactionAmount != null) {
        if (newTransactionAmount.discountedPrice && newTransactionAmount.discountedPrice !== newTransactionAmount.price) {
          this.transactionAmount = newTransactionAmount.discountedPrice;
          this.bookingService.updateFlightObjectWithNewFare(newTransactionAmount);
        } else {
          this.transactionAmount = newTransactionAmount.price;
          this.bookingService.updateFlightObjectWithNewFare(newTransactionAmount);
        }
      }
    });
    const updatedTransactionAmount = JSON.parse(this.gallopLocalStorage.getItem('selectedFlight'));
    console.log(updatedTransactionAmount)
    this.transactionAmount = updatedTransactionAmount.fareBreakup.price;

    this.disablePay = false;
  }

  onSubmit() {

    FormUtils.markFormGroupTouched(this.checkoutForm);

    if (this.checkoutForm.valid) {
      let userDetail = { name: this.checkoutForm.get('name').value };
      this.doPayment(userDetail);
    }
  }

  ngAfterViewInit() {

    let inputs = document.querySelectorAll('.cell.example.example2 .input');
    Array.prototype.forEach.call(inputs, function (input) {
      input.addEventListener('focus', function () {
        input.classList.add('focused');
      });
      input.addEventListener('blur', function () {
        input.classList.remove('focused');
      });
      input.addEventListener('keyup', function () {
        if (input.value.length === 0) {
          input.classList.add('empty');
        }
        else {
          input.classList.remove('empty');
        }
      });
    });

    let stripeElementStyle = this.getStripeClasses();

    this.card = this.stripeElements.create('card', stripeElementStyle);
    this.card.mount('#card');
    this.card.addEventListener('change', this.onStripeElementChange.bind(this));

  }

  onStripeElementChange(val) {
    let errors = null;
    if (val.empty || val.error) {
      errors = {
        empty: val.empty ? true : null,
        stripeError: val.error ? val.error.message : null
      };
    }

    this.setStripeErrors(val.elementType, errors);
  }

  private setStripeErrors(elementType, errors: any) {
    this.checkoutForm.get(elementType).markAsTouched({ onlySelf: true });
    this.checkoutForm.get(elementType).setErrors(errors);
  }


  private getStripeClasses() {
    let elementStyles = {
      base: {
        color: '#32325D',
        fontWeight: 500,
        fontFamily: 'Source Code Pro, Consolas, Menlo, monospace',
        fontSize: '14px',
        fontSmoothing: 'antialiased',

        '::placeholder': {
          color: '#CFD7DF',
        },
        ':-webkit-autofill': {
          color: '#e39f48',
        },
      },
      invalid: {
        color: '#E25950',
        '::placeholder': {
          color: '#FFCCA5',
        },
      }

    };

    let elementClasses = {
      focus: 'focused',
      empty: 'empty',
      invalid: 'invalid',
    };

    return {
      style: elementStyles,
      classes: elementClasses,
    };
  }


  private doPayment(userDetail: { name: any }) {

    this.disablePay = true;
    this.progressBar.start(Constants.PAYMENT);

    this.stripe.createToken(this.card, userDetail).then((result) => {
      this.progressBar.complete(Constants.PAYMENT);
      if (result.error) {
        let paymentModalRes = this.bookingService.getPaymentErrorMessageModal(result);
        this.bsModalRef = this.modalService.show(BookingMessageModalComponent, { initialState: paymentModalRes, backdrop: true, ignoreBackdropClick: true });
      } else {
        let details = {
          id: result.token.id,
          exp_month: result.token.card.exp_month,
          exp_year: result.token.card.exp_year,
          last4: result.token.card.last4,
          name: result.token.card.name,
          brand: result.token.card.brand
        }
        this.bookFlight(details);
      }
    });

  }

  bookFlight(details) {

    this.progressBar.start(Constants.BOOK);

    this.bookFlightSubscription = this.bookingService.bookFlight(false, 'PERSONAL_CARD', details, 'token', 0, null, null, null, null, null).subscribe((res) => {

      this.disablePay = false;
      this.progressBar.complete(Constants.BOOK);

      let bookingResponse: BookingResponse = deserialize(res, BookingResponse);
      this.gallopLocalStorage.setItem("bookingResponse", JSON.stringify(bookingResponse));

      this.bookingService.bookingResponse = bookingResponse;

      let bsModalRes = this.bookingService.getBookingModalResponse(bookingResponse, true);
      this.bsModalRef = this.modalService.show(BookingMessageModalComponent, { initialState: bsModalRes, backdrop: true, keyboard: false, ignoreBackdropClick: true });

      this.bsModalRef.content.acceptClickSubject.subscribe(data => {
        if (data) {
          this.onSubmit();
        }
      })
      if (this.confirmModalRef) {
        this.confirmModalRef.hide();
      }
    });
  }

  ngOnDestroy() {
    if (this.bookFlightSubscription) {
      this.bookFlightSubscription.unsubscribe();
    }
    if (this.flightFareChangeSubscription) {
      this.flightFareChangeSubscription.unsubscribe();
    }

    this.card.removeEventListener('change', this.onStripeElementChange);
    this.card.destroy();
    window.removeEventListener('beforeunload', this.showConfirmationPopup);
  }

  back() {
    if (this.disablePay) {
      this.confirmModalRef = this.modalService.show(ConfirmMessageModalComponent, { class: 'parent-modal', backdrop: true, keyboard: false, ignoreBackdropClick: true });
    }

    else {
      this.location.back();
    }
  }

}
